import { Vue, Component, Prop } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_CONST from '@/constants/AppConst';
import { IAccount, ICreateUser, IProgram, IUserRoles, roles } from '@/Model/programModel';
import APP_UTILITIES from "@/utilities/commonFunctions";
import ProgressButton from '@/commoncomponents/progressButton/progressButton.vue';
import DatepickerComponent from '@/ui-components/datepicker/DatepickerComponent.vue';
import UIkit from "uikit";
import { getProgramByAccount, getSiteByProgramId } from '@/services/create-user/createUserService';
import manageList from '@/store/modules/manageList';
import PrimaryAccountAdminAlert from '@/popupcomponents/primaryAccountAdminAlert/PrimaryAccountAdminAlert.vue';
import { IRoles, IRolesObj } from '@/Model/model';
import DropdownList from "@/ui-components/dropdownListBx/DropdownList.vue";
import programList from '@/store/modules/programList';
import MultiSelectDropdown from '@/ui-components/multiSelectDropdown/MultiSelectDropdown.vue';
import { getModule } from 'vuex-module-decorators';
import { GlobalModule } from '@/store/global/globalModule';
import store from '@/store';


@Component({
    components: {
        'progress-button': ProgressButton,
        'datepicker': DatepickerComponent,
        'error-popup': PrimaryAccountAdminAlert,
        'dropdown' : DropdownList,
        'multi-select-dropdown': MultiSelectDropdown,
    }
})
export default class addUpdateUser extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    user: ICreateUser = JSON.parse(JSON.stringify(APP_CONST.USERS_CREATION));
    validatedFields = JSON.parse(JSON.stringify(APP_CONST.VALIDATE_USER_FORM));
    accounts: IAccount[] = [] as IAccount[];
    account: IAccount = { id: 0, name: APP_CONST.BLANK };
    allFieldRequired = false;
    isApiError = false;
    isAccountAdmin = false;
    isPrimaryAccountAdmin = false;
    isValid = true;
    isPhoneLengthValid: boolean | null = null;
    apiErrorMessage = APP_CONST.BLANK;
    validEmail: null | boolean = null;
    apiResponseCode = 0;
    public hoverText: string = APP_CONST.BLANK;
    public hoverId: number = 0;
    public hoverStyleObj:any = {};
    public styleObj:any ={};
    public tooltipText: string = APP_CONST.BLANK;
    systemAccountRoles: { id: number, name: string, isChecked: boolean, disable: boolean }[] = JSON.parse(JSON.stringify(APP_CONST.SYSTEM_ROLE_LIST));
    systemProgramRoles: { id: number, name: string, isChecked: boolean, disable: boolean }[] = JSON.parse(JSON.stringify(APP_CONST.SYSTEM_PROGRAM_ROLE_LIST));
    systemSiteRoles: { id: number, name: string, isChecked: boolean, disable: boolean }[] = JSON.parse(JSON.stringify(APP_CONST.SYSTEM_SITE_ROLE_LIST));
    primaryAccountAdmin: string = APP_CONST.SYSTEM_ROLE_LIST[0].name;
    accountAdmin: string = APP_CONST.SYSTEM_ROLE_LIST[1].name;
    programAdmin: string = APP_CONST.SYSTEM_ROLE_LIST[2].name;
    sessionAdmin: string = APP_CONST.SYSTEM_ROLE_LIST[3].name;
    staff: string = APP_CONST.SYSTEM_ROLE_LIST[4].name;
    noAccess: string = APP_CONST.SYSTEM_ROLE_LIST[5].name;
    rolesList: { id: number, name: string, isChecked: boolean, disable: boolean }[] = [];
    updateProgress: boolean = false;
    accountAdminId = APP_CONST.ROLE_TYPE_ACCOUNT_ADMIN;
    duplicateUser = false;
    userRole: Array<any> = [] ;
    scheduleDateDisable:boolean = true;
    roleObj: IUserRoles = JSON.parse(JSON.stringify(APP_CONST.USERROLE_OBJ))
    roles:IRoles= JSON.parse(JSON.stringify(APP_CONST.ROLE))
    addprogramObj:IRolesObj = JSON.parse(JSON.stringify(APP_CONST.ADD_PROGRAM_OBJ))
    primaryAccountAdminRole : {id: number, isChecked: boolean, name: string, disable: boolean }  = APP_CONST.SYSTEM_ROLE_LIST[0];
    accountAdminRole : {id: number, isChecked: boolean, name: string, disable: boolean } = APP_CONST.SYSTEM_ROLE_LIST[1];
    programAdminRole : {id: number, isChecked: boolean, name: string, disable: boolean }  = APP_CONST.SYSTEM_ROLE_LIST[2];
    sessionAdminRole : {id: number, isChecked: boolean, name: string, disable: boolean }  = APP_CONST.SYSTEM_ROLE_LIST[3];
    staffRole : {id: number, isChecked: boolean, name: string, disable: boolean } = APP_CONST.SYSTEM_ROLE_LIST[4];
    noAccessRole : {id: number, isChecked: boolean, name: string, disable: boolean } = APP_CONST.SYSTEM_ROLE_LIST[5];
    public cellList: Array<string> = APP_CONST.PHONE_TYPE_OBJ;
    public positionList: Array<string> = APP_CONST.POSITION_OBJ;
    public phoneTypeDrop: boolean = false;
    public selectedPhoneType: string = APP_CONST.BLANK;
    public selectedPosition: string = APP_CONST.BLANK;
    public roleDrop: boolean = false;
    public addedRoleList: Array<string> = [];
    public currentRoleId: number = 0;
    public oldPrimaryAdminData: any = {};
    public onlyNoAccess: boolean = false;
    public enableAddPrivileges:boolean = false;
    public openAddPrivilegesAccordion:boolean = false;
    public allPrograms:any = [];
    public accountId:any;
    public siteList:any = [];
    public filteredUserRoles:any = [];
    public unfilteredProgramList:any=[];
    public scheduleDate:string = APP_CONST.BLANK;
    public saveEnable:boolean=false;
    public dateEntered:boolean = false;
    public accountAdminSelected:boolean = false;
    public isProgramsSelected:boolean=false;
    public programDetails=APP_CONST.SELECT_OBJECT;
    public programFullDataList :any=[];
    public allProgramDataCount:number = APP_CONST.ZERO;
    public scheduleDatecount:number = APP_CONST.ZERO;
    public revokeDateState:boolean = true;
    public revokeDate:string = APP_CONST.BLANK;
    public status = JSON.parse(JSON.stringify(APP_CONST.USER_STATUS));
    public alreadyAddedInfo:boolean=APP_CONST.FALSE;;
    public statusChangedTo:number=APP_CONST.ZERO;
    public activeStatus:string=APP_CONST.ACTIVE;
    public inactiveStatus:string=APP_CONST.INACTIVE;
    public selectedPrograms: Array<number> = [];
    public todayDate = APP_CONST.BLANK;
    public revokeDateError:boolean = APP_CONST.FALSE;
    public scheduleDateError:boolean = APP_CONST.FALSE;
    public alreadyEnableScheduleDate:boolean = APP_CONST.FALSE;
    public isPrivilegePresent:boolean = APP_CONST.FALSE;
    public firstTimeEdit:boolean = false;
    public dropdownConfig = APP_CONST.USERS_DROPDOWN_CONFIG;
    public singleDropdownConfig = APP_CONST.USERS_SINGLE_DROPDOWN_CONFIG;
    public getGlobalState = getModule(GlobalModule, store)


    get emailMandat() {
        let emailMandatory = false;
        if (this.addedRoleList.includes(this.accountAdmin)||
            this.addedRoleList.includes(this.primaryAccountAdmin)||
            this.addedRoleList.includes(this.programAdmin)||
            this.addedRoleList.includes(this.sessionAdmin)||
            this.addedRoleList.includes(this.staff)) {
            emailMandatory = true;
            this.onlyNoAccess=false;
        }else{
            emailMandatory=false;
            this.onlyNoAccess=true;
        }
        return emailMandatory;
    }

    get siteData(){
        return programList.siteDetailsList;
    }

    isRevokeDateFilled(){
        let dateSelected = this.dateFormat(this.revokeDate);
        let scheduleDate = this.dateFormat(this.scheduleDate);
        if(!this.revokeDateState && dateSelected == this.todayDate ){
            this.scheduleDateError = APP_CONST.FALSE;
            this.revokeDateState = true;
            this.status.value = APP_CONST.INACTIVE;
            this.selectStatus({id:APP_CONST.USER_STATUS_INACTIVE,value:APP_CONST.INACTIVE});
        }else if (dateSelected == scheduleDate && this.scheduleDateState == APP_CONST.FALSE){
            this.scheduleDate = APP_CONST.BLANK;
            this.scheduleDateError = APP_CONST.TRUE;
            this.revokeDateError = APP_CONST.FALSE;
        }else{
            this.scheduleDateError = APP_CONST.FALSE;
            this.revokeDateError = APP_CONST.FALSE;
        }

    }

    get programsRemaining(){
        const filter = this.programList.filter((prog:any) => 
                !this.filteredUserRoles.map((program:any)=>program).includes(prog.id)
         )

        return filter
    }

    isScheduleDateFilled(){
        let dateSelected = this.dateFormat(this.revokeDate);
        let scheduleDate = this.dateFormat(this.scheduleDate);
        if( scheduleDate == dateSelected && this.revokeDateState == APP_CONST.FALSE){
            this.revokeDate = APP_CONST.BLANK;
            this.revokeDateError = APP_CONST.TRUE;
            this.scheduleDateError = APP_CONST.FALSE;
        }else{
            this.revokeDateError = false;
            this.scheduleDateError = APP_CONST.FALSE;
        }
    }
   

    programsMultiSelect(userRole:any){      
    if(userRole.programs && userRole.programs.length){
           const filter = this.programList.filter((prog:any) => 
                !this.filteredUserRoles.map((program:any)=>program).includes(prog.id) || userRole.programs.map((ele:any)=>ele.id).includes(prog.id)
            )
        this.allPrograms = filter;
        return filter
    }
        else{
        const filter = this.programList.filter((prog:any) => 
            !this.filteredUserRoles.map((program:any)=>program).includes(prog.id)
        )
        this.allPrograms = filter;
        return filter
        }
    }

    sitesMultiSelect(userRole:any){      
        let checkDropdownArray: Array<any> = [];
        userRole && userRole.totalSites && userRole.totalSites.forEach((data: { siteId: any; siteName: any; isChecked: any; })=>{
            checkDropdownArray.push({id:data.siteId, value:data.siteName, checked:data.isChecked})
        })
    return checkDropdownArray
    }

    get positionDropdown(){
        let checkDropdownArray: Array<any> = [];
        this.positionList.forEach((data:string)=>{
                checkDropdownArray.push({id:data, value:data})
         })
        return checkDropdownArray;
    }

    get phoneTypeDropdown(){
        let checkDropdownArray: Array<any> = [];
        this.cellList.forEach((data:string)=>{
                checkDropdownArray.push({id:data, value:data})
         })
        return checkDropdownArray;

    }


    @Prop({default: ''})
    component!: string;

    @Prop()
    viewUserDetailsData!:any

    @Prop()
    programRoleData!:{role:string,positions:string[],userRoleId:string,programs:{id:number,name:string}[]}

    @Prop()
    otherRolesViewData!:[]

    @Prop()
    accountAdminRoleViewId!:number

    @Prop()
    primaryaccountAdminRoleViewId !:number

    get addEditViewModeValue(){
        return programList.addEditViewModeValue
    }

    get programList()  
    {
        this.filteredUserRoles = [];
        const programs = this.unfilteredProgramList;
        let userRoles: any = [];
        let userRolesPrograms: any = [];
        this.userRole.forEach((element: any) => {
            if (element.programs && element.programs.length) {
                userRoles = element.programs;
                for (let i = 0; i < userRoles.length; i++) {
                    if (userRoles[i].id > APP_CONST.ZERO) {
                        this.filteredUserRoles.push(userRoles[i].id)
                    }
                }
            }

            if (element.addProgram && element.addProgram.length) {
                userRolesPrograms = element.addProgram;
                for (let i = 0; i < userRolesPrograms.length; i++) {
                    if (userRolesPrograms[i].programs && userRolesPrograms[i].programs.length && userRolesPrograms[i].programs[APP_CONST.ZERO].id > APP_CONST.ZERO) {
                        this.filteredUserRoles.push(userRolesPrograms[i].programs[APP_CONST.ZERO].id)
                    }
                }
            }
            
        })
        
        return programs;
    }

    filteredProgramList(userRole:any)
    { 
        if(userRole.programs && userRole.programs.length){
            return this.programList.filter((prog:any) => 
                !this.filteredUserRoles.map((program:any)=>program).includes(prog.id) || userRole.programs.map((ele:any)=>ele.id).includes(prog.id)
            )
        }
        else{
            return this.programList.filter((prog:any) => 
            !this.filteredUserRoles.map((program:any)=>program).includes(prog.id)

        )
        }
    }

    isDisabled(userRoleObj:any){
            let disable:boolean = false;
            if (userRoleObj.programs && userRoleObj.programs.length == APP_CONST.ONE && userRoleObj.programs[APP_CONST.ZERO].name === "Select") {
                userRoleObj.programs = [];
            }
            if (userRoleObj.programs && !this.programsRemaining.length && !userRoleObj.programs.length) {
                disable = true;
                userRoleObj.programError = false;
                userRoleObj.siteError = false;
                userRoleObj.accordionError = false;
                if(userRoleObj.position && userRoleObj.position == APP_CONST.OTHER_POSITION){
                    userRoleObj.otherPositionShow = APP_CONST.FALSE;
                    userRoleObj.position = APP_CONST.BLANK;
                    userRoleObj.otherPosition = APP_CONST.BLANK;
                }else{
                    userRoleObj.position = APP_CONST.BLANK;
                }
            }
            return disable;
    }

    get scheduleDateState() {
        if(this.addEditViewModeValue== APP_CONST.ADD_MODE ){
            this.scheduleDateDisable = true;
            this.userRole.forEach((element: any) => {
                if (element.roleId == this.accountAdminRole.id) {
                    this.scheduleDateDisable = false;
                }
                if (element.roleId == this.programAdminRole.id && element.programs && element.programs.length) {
                    this.scheduleDateDisable = false;
                }
                if (element.roleId == this.sessionAdminRole.id && element.programs && element.programs.length) {
                    if (element.sites && element.sites.length) {
                        this.scheduleDateDisable = false;
                    }
                }
                if (element.roleId == this.staffRole.id && element.programs && element.programs.length) {
                    if (element.sites && element.sites.length) {
                        this.scheduleDateDisable = false;
                    }
                }
    
                if (element.roleId == this.noAccessRole.id && element.programs && element.programs.length) {
                    if (element.sites && element.sites.length) {
                        this.scheduleDateDisable = false;
                    }
                }
            })          
        if(this.scheduleDateDisable){
            this.scheduleDate=APP_CONST.BLANK;
        }
        }
        return this.scheduleDateDisable;
    }

    checkForDisableState(element:any){
        if((element.roleId == this.programAdminRole.id && element.programs && element.programs.length)||(element.roleId == this.sessionAdminRole.id||element.roleId == this.staffRole.id|| element.roleId == this.noAccessRole.id) && element.programs && element.programs.length && element.sites && element.sites.length) {
            this.scheduleDateDisable = false;
        }
    }

    get saveButtonEnable() {
        this.accountAdminSelected = this.addedRoleList.includes(this.accountAdmin || this.programAdmin)
        this.userRole.forEach((element: any) => {
            if (element.roleId == this.accountAdminId) {
                element.allMandatoryFieldsFilled = true;
            }
            if (element.roleId == this.programAdminRole.id && element.isShow == true) {
                element.allMandatoryFieldsFilled = false;
                if (element.programs && element.programs.length) {
                    element.allMandatoryFieldsFilled = true;
                } else {
                    element.allMandatoryFieldsFilled = false;
                }
            } else if (element.roleId == this.programAdminRole.id && element.isShow == false) {
                element.allMandatoryFieldsFilled = true;
            }

            if (element.roleId == this.sessionAdminRole.id && element.isShow == true) {
                element.allMandatoryFieldsFilled = false;
                if (element.programs && element.programs.length) {
                    element.allMandatoryFieldsFilled = false;
                    if (element.sites && element.sites.length) {
                        element.allMandatoryFieldsFilled = true;
                        if (element.addProgram && element.addProgram.length && element.addProgramIsShow) {
                            element.addProgram.forEach((ele: any) => {
                                element.allMandatoryFieldsFilled = false
                                if (ele.programs && ele.programs.length) {
                                    element.allMandatoryFieldsFilled = false;
                                    if (ele.sites && ele.sites.length) {
                                        element.allMandatoryFieldsFilled = true;
                                    } else {
                                        element.allMandatoryFieldsFilled = false;
                                    }
                                }
                            })
                        }
                    } else {
                        element.allMandatoryFieldsFilled = false;
                    }
                }
            }


            if (element.roleId == this.staffRole.id && element.isShow == true) {
                element.allMandatoryFieldsFilled = false;
                if (element.programs && element.programs.length) {
                    element.allMandatoryFieldsFilled = false;
                    if (element.sites && element.sites.length) {
                        element.allMandatoryFieldsFilled = true;
                        if (element.addProgram && element.addProgram.length && element.addProgramIsShow) {
                            element.addProgram.forEach((ele: any) => {
                                element.allMandatoryFieldsFilled = false
                                if (ele.programs && ele.programs.length) {
                                    element.allMandatoryFieldsFilled = false;
                                    if (ele.sites && ele.sites.length) {
                                        element.allMandatoryFieldsFilled = true;
                                    } else {
                                        element.allMandatoryFieldsFilled = false;
                                    }
                                }
                            })
                        }
                    } else {
                        element.allMandatoryFieldsFilled = false;
                    }
                }
            }

            if (element.roleId == this.noAccessRole.id && element.isShow == true) {
                element.allMandatoryFieldsFilled = false;
                if (element.programs && element.programs.length) {
                    element.allMandatoryFieldsFilled = false;
                    if (element.sites && element.sites.length) {
                        element.allMandatoryFieldsFilled = true;
                        if (element.addProgram && element.addProgram.length && element.addProgramIsShow) {
                            element.addProgram.forEach((ele: any) => {
                                element.allMandatoryFieldsFilled = false
                                if (ele.programs && ele.programs.length) {
                                    element.allMandatoryFieldsFilled = false;
                                    if (ele.sites && ele.sites.length) {
                                        element.allMandatoryFieldsFilled = true;
                                    } else {
                                        element.allMandatoryFieldsFilled = false;
                                    }
                                }
                            })
                        }
                    } else {
                        element.allMandatoryFieldsFilled = false;
                    }
                }
            }
        });

        this.saveEnable = this.userRole && Object.keys(this.userRole).every((element:any) => this.userRole[element].allMandatoryFieldsFilled);
        
        return this.saveEnable
    }

    get scheduleDateFilled() {
        this.dateEntered = false;
        if (!this.scheduleDateDisable && this.scheduleDate && this.scheduleDate != APP_CONST.BLANK) {
            this.dateEntered = true;
        } else if (!this.scheduleDateDisable && this.scheduleDate && this.scheduleDate == APP_CONST.BLANK) {
            this.dateEntered = false;
        } else if (this.scheduleDateDisable) {
            this.dateEntered = true;
        }

        if(this.userRole.length == APP_CONST.ONE && this.userRole[0].roleId == this.noAccessRole.id){
            this.dateEntered = true;
        }
        return this.dateEntered
    }


    mounted() {
        this.getRoleList();
    }

    public getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }
    
    getRoleList() {
         let date = new Date();
         this.todayDate =
            ("0" + (date.getUTCMonth()+1)).slice(-2) + "/" +
            ("0" + (date.getUTCDate())).slice(-2) + "/" +
            date.getUTCFullYear()
            
        if(this.currentRoleId>=4){
            var role: any = APP_UTILITIES.getCookie("user_role");
            var userInfo = JSON.parse(role);
            this.accountId =userInfo[0].accountId;
        }else{
            this.accountId = APP_UTILITIES.getCookie('accountId');
            this.accountId = JSON.parse(this.accountId)
        }
        let userRoles: any = APP_UTILITIES.getCookie("highest_role");
        let userData = userRoles && JSON.parse(userRoles);
        this.currentRoleId = userData;
        if (this.currentRoleId <= 4) {
            this.rolesList = JSON.parse(JSON.stringify(this.systemAccountRoles));
        } else if (this.currentRoleId == 5) {
            this.rolesList = JSON.parse(JSON.stringify(this.systemProgramRoles));
        } else if (this.currentRoleId == 6) {
            this.rolesList = JSON.parse(JSON.stringify(this.systemSiteRoles));
        }
        this.getPrograms(this.accountId);
    }

    selectRoles(roleDetails: { id: number, isChecked: boolean, name: string, disable: boolean }) {
        if(this.addEditViewModeValue == APP_CONST.EDIT_MODE){
            this.firstTimeEdit = APP_CONST.FALSE;
        }
        let roleIndex = this.rolesList.findIndex((role: any) => role.name == roleDetails.name)
        this.rolesList[roleIndex].isChecked = roleDetails.isChecked
        if (roleDetails.isChecked && !this.addedRoleList.includes(roleDetails.name)) {
            this.addedRoleList.push(roleDetails.name)
        } else if (!roleDetails.isChecked && this.addedRoleList.includes(roleDetails.name)) {
            let addedRoleIndex = this.addedRoleList.findIndex((name: any) => name == roleDetails.name)
            this.addedRoleList.splice(addedRoleIndex, 1)
        }

        this.roleCheck(roleDetails);
        if((this.addedRoleList.includes(this.accountAdmin)|| this.addedRoleList.includes(this.primaryAccountAdmin)) && this.addedRoleList.length == APP_CONST.ONE){
            this.enableAddPrivileges = false;
        }else if((this.addedRoleList.includes(this.accountAdmin)|| this.addedRoleList.includes(this.primaryAccountAdmin)) && this.addedRoleList.length > APP_CONST.ONE && roleDetails.id != this.accountAdminId){
            this.enableAddPrivileges = true;
        }else if((!this.addedRoleList.includes(this.accountAdmin) && !this.addedRoleList.includes(this.primaryAccountAdmin)) && this.addedRoleList.length >= APP_CONST.ONE){
            this.enableAddPrivileges = true;
        }
        if (!roleDetails.isChecked) {
            let index = this.userRole.findIndex((roleElement: any) => roleElement.roleName == roleDetails.name)
            if (index > -1) {
                this.userRole.splice(index, 1)
            }
            if(this.addEditViewModeValue == APP_CONST.EDIT_MODE){
            let roleIndex = this.viewUserDetailsData.allUniqueRolesArray.findIndex((roleElement: any) => roleElement == roleDetails.name)
            if (roleIndex > -1) {
                this.viewUserDetailsData.allUniqueRolesArray.splice(roleIndex, 1)
            }
            }
            for(let role=0;role < this.userRole.length;role++){
                if(this.userRole[role].isShow == true){
                    this.enableAddPrivileges = false;
                }
            }
            if (this.addedRoleList.length == 0 && (roleDetails.name == this.programAdmin || roleDetails.name == this.sessionAdmin || roleDetails.name == this.staff || roleDetails.name == this.noAccess)) {
                this.enableAddPrivileges = false;
            }else if(this.addEditViewModeValue== APP_CONST.EDIT_MODE && (this.addedRoleList.length == APP_CONST.ZERO && ( roleDetails.id == this.accountAdminRole.id || roleDetails.id != this.accountAdminRole.id)|| this.addedRoleList.length == APP_CONST.ONE && roleDetails.id == this.accountAdminRole.id)){
                this.scheduleDatecount += APP_CONST.ONE;
                this.getDateState();
            }
        }
        this.updateStructure();
    }

    roleCheck(roleDetails: { id: number, isChecked: boolean, name: string, disable: boolean }) {
        if (roleDetails.name == this.primaryAccountAdmin) {
            if (this.addedRoleList.includes(this.accountAdmin)) {
                let roleIndex = this.rolesList.findIndex((role: any) => role.name == this.accountAdmin)
                this.rolesList[roleIndex].isChecked = false;
                let addedRoleIndex = this.addedRoleList.findIndex((name: any) => name == this.accountAdmin)
                this.addedRoleList.splice(addedRoleIndex, 1)
            }
        } else if (roleDetails.name == this.accountAdmin) {
            if (this.addedRoleList.includes(this.primaryAccountAdmin)) {
                let roleIndex = this.rolesList.findIndex((role: any) => role.name == this.primaryAccountAdmin)
                this.rolesList[roleIndex].isChecked = false;
                let addedRoleIndex = this.addedRoleList.findIndex((name: any) => name == this.primaryAccountAdmin)
                this.addedRoleList.splice(addedRoleIndex, 1)
            }
        }
        this.disableCheck(roleDetails)

    }

    disableCheck(roleDetails: { id: number, isChecked: boolean, name: string, disable: boolean }) {
        if (roleDetails.id == APP_CONST.ROLE_TYPE_ACCOUNT_ADMIN && roleDetails.isChecked) {
            for (let i = roleDetails.id - 1; i < this.rolesList.length; i++) {
                this.rolesList[i].disable = true;
            }
        } else if ((roleDetails.id == APP_CONST.SESSION_ADMIN_ROLE_ID || roleDetails.id == APP_CONST.STAFF_ROLE_ID || roleDetails.id == APP_CONST.ROLE_TYPE_NONSYSTEM) && roleDetails.isChecked) {
            for (let i = 0; i < this.rolesList.length - 4; i++) {
                this.rolesList[i].disable = true;
            }
        } else if (roleDetails.id == APP_CONST.ROLE_TYPE_PROGRAM_ADMIN && (this.addedRoleList.includes(this.accountAdmin) || this.addedRoleList.includes(this.primaryAccountAdmin) && (roleDetails.isChecked || !roleDetails.isChecked))) {
            for (let i = 3; i < this.rolesList.length; i++) {
                this.rolesList[i].disable = true;
            }
        } else if (roleDetails.id == 5 && (this.addedRoleList.includes(this.sessionAdmin) || this.addedRoleList.includes(this.staff) || this.addedRoleList.includes(this.noAccess) && (roleDetails.isChecked || !roleDetails.isChecked))) {
            for (let i = 0; i < this.rolesList.length - 4; i++) {
                this.rolesList[i].disable = true;
            }
        } else if ((roleDetails.id == APP_CONST.SESSION_ADMIN_ROLE_ID || roleDetails.id == APP_CONST.STAFF_ROLE_ID || roleDetails.id == APP_CONST.ROLE_TYPE_NONSYSTEM) && !roleDetails.isChecked) {
            if (this.addedRoleList.includes(this.staff) || this.addedRoleList.includes(this.noAccess) || this.addedRoleList.includes(this.sessionAdmin)) {
                for (let i = 0; i < this.rolesList.length - 4; i++) {
                    this.rolesList[i].disable = true;
                }
            } else {
                for (let i = 0; i < this.rolesList.length - 4; i++) {
                    this.rolesList[i].disable = false;
                }
            }
        } else {
            for (let i = 0; i < this.rolesList.length; i++) {
                this.rolesList[i].disable = false;
            }
        }
    }


    validate() {
        this.duplicateUser = false;
        this.apiErrorMessage = APP_CONST.BLANK;
        this.isPhoneLengthValid = null;
        const ValidateObj = JSON.parse(JSON.stringify(APP_CONST.VALIDATE_USER_FORM));
        const validation = APP_UTILITIES.requiredFields(this.user, ValidateObj);
        this.validatedFields = { ...validation.validateData };
        this.allFieldRequired = validation.requiredFieldsFilled;
        if (validation.requiredFieldsFilled) {
            return false;
        }
        if (validation.isFormInvalid) {
            return false;
        }

        if (this.emailMandat || this.user.email) {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            this.validEmail = re.test(this.user.email)
            if (!this.validEmail) return false;
        }

        this.user.phoneNumber = APP_UTILITIES.removeSpecialCharacters(this.user.phoneNumber);
        if (this.user.phoneNumber && (this.user.phoneNumber.length !== 10)) {
            this.isPhoneLengthValid = false;
            return false;
        } else {
            this.isPhoneLengthValid = true;
        }

        if (this.validatedFields.firstName.alphaCharError || this.validatedFields.lastName.alphaCharError) {
            return false;
        }
        return true;
    }

    createUser() {
        if (this.isPrimaryAccountAdmin && (this.addEditViewModeValue == APP_CONST.ADD_MODE || (this.addEditViewModeValue == APP_CONST.EDIT_MODE && !this.viewUserDetailsData.allUniqueRolesArray.includes(this.primaryAccountAdmin)))) {
            manageList.getPrimaryAccountAdminDetails(this.accountId).then((response: any) => {
                if (response.status == APP_CONST.RESPONSE_200) {
                    if (this.isPrimaryAccountAdmin) {
                        if (UIkit.modal('#admin-confirmation')) {
                            UIkit.modal('#admin-confirmation').show();
                        }
                    }
                    this.oldPrimaryAdminData = response.data[0].user
                    manageList.mutateAccountAdminDetails(this.oldPrimaryAdminData)
                } else if (response.status == APP_CONST.RESPONSE_204) {
                    if(this.addEditViewModeValue == APP_CONST.ADD_MODE){
                        this.addUser();
                    }else if(this.addEditViewModeValue == APP_CONST.EDIT_MODE){
                        this.editUser();
                    }
                }
            })
        } else {
            if (this.addEditViewModeValue == APP_CONST.ADD_MODE) {
                this.addUser();
            } else if(this.addEditViewModeValue == APP_CONST.EDIT_MODE){
                this.editUser();
            }
        }

    }

    addUser() {
        this.apiResponseCode = 0;
        this.isValid = false;
        manageList.addUser(this.user).then((response: any) => {
            if (response.status == APP_CONST.RESPONSE_200) {
                this.apiResponseCode = APP_CONST.RESPONSE_200;
                setTimeout(() => {
                    this.close();
                    this.refillForm();
                }, 500);
            } else if (response.status === APP_CONST.RESPONSE_400) {
                this.apiResponseCode = APP_CONST.RESPONSE_400;
                this.isApiError = true;
                this.apiErrorMessage = response.data;
            } else if (response.status === APP_CONST.RESPONSE_500) {
                this.refillForm();
            }else if(response.status === APP_CONST.RESPONSE_406){
                this.apiResponseCode = APP_CONST.RESPONSE_400;
                this.duplicateUser = true;
            }
        })
    }

    editUser(){
        this.apiResponseCode = 0;
        this.isValid = false;
        manageList.editUser(this.user).then((response: any) => {
            if (response.status == APP_CONST.RESPONSE_200) {
                this.apiResponseCode = APP_CONST.RESPONSE_200;
                setTimeout(() => {
                    this.close();
                    this.refillForm();
                }, 500);
            } else if (response.status === APP_CONST.RESPONSE_400) {
                this.apiResponseCode = APP_CONST.RESPONSE_400;
                this.isApiError = true;
                this.apiErrorMessage = response.data;
            } else if (response.status === APP_CONST.RESPONSE_500) {
                this.refillForm();
            }else if(response.status === APP_CONST.RESPONSE_406){
                this.apiResponseCode = APP_CONST.RESPONSE_400;
                this.duplicateUser = true;
            }
        })
    }

    formatNumber(phoneNumber: string){
        return phoneNumber && APP_UTILITIES.formatNumber(phoneNumber);
    }

    dateFormat(data: string) {
        const date = APP_UTILITIES.formatShortDate(data);
        return date;
    }

    continuePopup(data: boolean) {
        if (data && this.addEditViewModeValue == APP_CONST.ADD_MODE) {
            this.addUser();
        }else if(data && this.addEditViewModeValue == APP_CONST.EDIT_MODE){
            this.editUser();
        }
        setTimeout(() => {
            if (UIkit.modal('#admin-confirmation')) {
                UIkit.modal('#admin-confirmation').hide();
            }
        }, 700);
    }

    updateStructure() {
        this.addedRoleList.forEach(roleName => {
            let rolePresent:boolean = this.viewUserDetailsData && this.viewUserDetailsData.allUniqueRolesArray && this.viewUserDetailsData.allUniqueRolesArray.includes(roleName);
            if(rolePresent && this.firstTimeEdit){
                let index = this.rolesList.findIndex((ele: any) => ele.name == roleName)
                this.roleObj.roleId = this.rolesList[index].id;
                this.roleObj.accountId = this.accountId;
                this.roleObj.status = APP_CONST.STATUS_ACTIVE;
                if (roleName == this.primaryAccountAdmin) {
                    this.roleObj.isPrimaryAccountAdmin = true;
                    this.isPrimaryAccountAdmin = true;
                }else{
                    this.roleObj.isPrimaryAccountAdmin = false;
                    this.isPrimaryAccountAdmin = false;
                }
    
                this.roles = JSON.parse(JSON.stringify(this.roleObj));
                this.roles.roleName = roleName;
                this.roles.isShow = true;
                this.roles.accordOpen = true;
                this.roles.positionDropdownState = false;
                this.roles.programButtonShow = false;
                this.roles.allProgramsSelected = false;
                this.roles.allSiteSelected = false;
                this.roles.programError = false;
                this.roles.siteError = false;
                this.roles.toggleIndex = APP_CONST.ONE;
                this.roles.accordionError = false;
                this.roles.allMandatoryFieldsFilled=true;
            if(this.viewUserDetailsData && (this.viewUserDetailsData.allUniqueRolesArray.includes(this.accountAdminRole.name)||this.viewUserDetailsData.allUniqueRolesArray.includes(this.primaryAccountAdminRole.name)) && this.viewUserDetailsData.allUniqueRolesArray.length == APP_CONST.ONE){
                this.enableAddPrivileges = APP_CONST.FALSE;
            }else{
                if (this.roles.roleName != this.programAdmin) {
                    this.fillEditDataForOtherRoles(this.roles);
                }else{
                    this.fillEditDataForProgramAdmin(this.roles);
                }
            }
            }else{
                this.scheduleDatecount += APP_CONST.ONE;
                let index = this.rolesList.findIndex((ele: any) => ele.name == roleName)
                this.roleObj.roleId = this.rolesList[index].id;
                this.roleObj.accountId = this.accountId;
                this.roleObj.status = APP_CONST.STATUS_ACTIVE;
                if (roleName == this.primaryAccountAdmin) {
                    this.roleObj.isPrimaryAccountAdmin = true;
                    this.isPrimaryAccountAdmin = true;
                }else{
                    this.roleObj.isPrimaryAccountAdmin = false;
                    this.isPrimaryAccountAdmin = false;
                }
                this.roles = JSON.parse(JSON.stringify(this.roleObj));
                this.roles.roleName = roleName;
                this.roles.position = APP_CONST.BLANK;
                this.roles.programs = [];
                this.roles.otherPosition = APP_CONST.BLANK;
                this.roles.positionDropdownState = false;
                this.roles.accordOpen = true;
                this.roles.isShow = false;
                this.roles.programButtonShow = false;
                this.roles.allProgramsSelected = false;
                this.roles.allSiteSelected = false;
                this.roles.programError = false;
                this.roles.siteError = false;
                this.roles.toggleIndex = APP_CONST.ONE;
                this.roles.accordionError = false;
                this.roles.allMandatoryFieldsFilled=true;
                this.roles.newPrivilegesAdded = [];
                if (this.roles.roleName != this.programAdmin) {
                    this.roles.sites = [];
                    this.roles.totalSites = [];
                    this.roles.programButtonShow = true;
                    this.roles.addProgramIsShow = false;
                    this.roles.addProgram = [];
                }
               
            }

            if (this.roles.roleId == this.accountAdminId) {
                let duplicateRoleIdIndex: any = this.userRole.findIndex((role: any) => role.roleId == this.roles.roleId);
                if (duplicateRoleIdIndex >= APP_CONST.ZERO) {
                    this.userRole[duplicateRoleIdIndex] = this.roles;
                }
            }
          
            let userRoleIndex = this.userRole && this.userRole.findIndex((element: any) => element.roleName == this.roles.roleName)
            if (userRoleIndex > -1) {
                this.userRole = JSON.parse(JSON.stringify(this.userRole))
            } else {
                this.userRole.push(JSON.parse(JSON.stringify(this.roles)))
                
            }

            if(this.addEditViewModeValue == APP_CONST.EDIT_MODE){
                let enable = this.userRole && this.userRole.filter((element:any)=> element.isShow == APP_CONST.FALSE && element.roleId != this.accountAdminId);
                if(enable && enable.length){
                    this.enableAddPrivileges = true;
                }
            }

            if(!rolePresent && this.addEditViewModeValue == APP_CONST.EDIT_MODE && ((roleName == this.accountAdminRole.name || roleName == this.primaryAccountAdminRole.name)||!this.addedRoleList.includes(this.accountAdminRole.name||this.primaryAccountAdminRole.name))){
                this.scheduleDatecount += APP_CONST.ONE;
                this.roles.newPrivilegesAdded = [];
                this.getDateState();
            }         
        })
    }

    fillEditDataForProgramAdmin(roles:IRoles){
        roles.newPrivilegesAdded = [];
        roles.programs = [];
        roles.position = APP_CONST.BLANK;
        roles.otherPosition = APP_CONST.BLANK;
        roles.otherPositionShow = false;
        let programArray:any = [];
        this.programRoleData && this.programRoleData.programs && this.programRoleData.programs.forEach((ele:any) =>{programArray.push(ele)})
        roles.programs = programArray;
        
        if(this.programRoleData && this.programRoleData.programs && this.programRoleData.programs.length){
            if(this.programRoleData.positions[0]){
                if(!this.positionList.includes(this.programRoleData.positions[0])){
                    roles.otherPositionShow = true;
                    roles.position = APP_CONST.OTHER_POSITION;
                    roles.otherPosition = this.programRoleData.positions[0]
                }else{
                    roles.otherPositionShow = false;
                    roles.position = this.programRoleData.positions[0];
                }
            }
            this.allPrograms && this.allPrograms.forEach((program: { id:number;name: string; checked: boolean; }) =>{
                this.programRoleData.programs.forEach(ele =>{
                    if(program.id == ele.id){
                        program.checked = true;
                    }
                })
                
            })
            this.allPrograms=JSON.parse(JSON.stringify(this.allPrograms))  
            this.checkSelectionState(roles);
            this.enableAddPrivileges = false;
        }else{
            roles.isShow = false;
            roles.id = this.programRoleData ? Number(this.programRoleData.userRoleId) : APP_CONST.ZERO;
            this.enableAddPrivileges = true;
        }
       
    }

    fillEditDataForOtherRoles(roles:IRoles){
        this.isPrivilegePresent = false;
        roles.newPrivilegesAdded = [];
        roles.totalSites = [];
        roles.addProgram = [];
        roles.programs = [];
        roles.position = APP_CONST.BLANK;
        roles.otherPosition = APP_CONST.BLANK;
        roles.sites =[];
        roles.programButtonShow = true;
        roles.addProgramIsShow = false;
        roles.addProgram = [];
        roles.otherPositionShow = false;
        this.otherRolesViewData && this.otherRolesViewData.forEach((ele:{programData:any,role:string,userRoleId:string},index:number) =>{
            if(ele.role == roles.roleName){
                roles.id = Number(ele.userRoleId);
                if(ele.programData){       
                    let programsArray:any=[];
                    programsArray.push(ele.programData[0].programName);
                    
                    if(programsArray && programsArray[0].id != APP_CONST.ZERO){
                        if(ele.programData[0].positions[0]){
                            if (!this.positionList.includes(ele.programData[0].positions[0])) {
                                roles.otherPositionShow = true;
                                roles.position = APP_CONST.OTHER_POSITION;
                                roles.otherPosition = ele.programData[0].positions[0];
                            } else {
                                roles.otherPositionShow = false;
                                roles.position = ele.programData[0].positions[0];
                            }
                        }
                        this.isPrivilegePresent = true;
                        roles.programs = programsArray; 
                    let siteArray:any = [];
                    ele.programData[0].sites.forEach((ele: any) => { siteArray.push(ele) })
                    roles.sites = siteArray;
                    this.getSite(roles,index,-1);
                    roles.programButtonShow = true;
                    roles.addProgramIsShow = true;
                    
            }
            if(ele.programData && ele.programData.length > APP_CONST.ONE){
                let addProgramsArray:any = [];
                for(let i=1;i < ele.programData.length;i++){
                    roles.addProgramIsShow=true;
                    roles.programButtonShow = true;
                    addProgramsArray.push(JSON.parse(JSON.stringify(this.addprogramObj)));
                    let programsArray:any=[];
                    programsArray.push(ele.programData[i].programName);
                    addProgramsArray[i-1].programs= programsArray;
                    let siteArray:any = [];
                    ele.programData[i].sites.forEach((ele:any) =>{siteArray.push(ele)})
                    addProgramsArray[i-1].sites = siteArray;
                    if(ele.programData[i].positions[0]){
                        if(!this.positionList.includes( ele.programData[i].positions[0])){
                            addProgramsArray[i-1].otherPositionShow = true;
                            addProgramsArray[i-1].position = APP_CONST.OTHER_POSITION;
                            addProgramsArray[i-1].otherPosition =  ele.programData[i].positions[0];
                        }else{
                            addProgramsArray[i-1].otherPositionShow = false;
                            addProgramsArray[i-1].position =  ele.programData[i].positions[0];
                        }
                    }
                    addProgramsArray[i-1].roleId=roles.roleId;
                    addProgramsArray[i-1].roleName=roles.roleName;
                    addProgramsArray[i-1].newPrivilegesAdded=[];
                    this.getSite(addProgramsArray[i-1],index,i-1);
                    roles.addProgram = addProgramsArray;
                    
                }
            }
        }
        } 
        })

        if(!this.isPrivilegePresent){
            roles.isShow = false;
            this.enableAddPrivileges = true;
        }else{
            this.enableAddPrivileges = false;
        }
        
    }

    updateUserRoleStructure() {
        this.user.status = this.status.id;
        this.user.userRoles = [];
        let obj: IUserRoles = JSON.parse(JSON.stringify(APP_CONST.USERROLE_OBJ));
        this.userRole.forEach((element: any) => {
            if (element.roleId == this.accountAdminId) {
                obj.accountId = element.accountId;
                obj.isPrimaryAccountAdmin = element.isPrimaryAccountAdmin;
                obj.roleId = element.roleId;
                obj.status = element.status;
                obj.siteId = element.siteId;
                if (element.position == APP_CONST.OTHER_POSITION) {
                    obj.programRole = element.otherPosition
                } else {
                    obj.programRole = element.position
                }
                if(this.addEditViewModeValue == APP_CONST.EDIT_MODE){
                    if( element.isPrimaryAccountAdmin ){
                        obj.id = this.primaryaccountAdminRoleViewId;
                    }else{
                        obj.id = this.accountAdminRoleViewId;
                    }
                }
                obj.programId = element.programId;
                this.user.userRoles.push(JSON.parse(JSON.stringify(obj)))

            }
            if (element.roleId == this.programAdminRole.id) {
                obj.accountId = element.accountId;
                obj.isPrimaryAccountAdmin = element.isPrimaryAccountAdmin;
                obj.roleId = element.roleId;
                obj.status = element.status;
                obj.siteId = APP_CONST.ZERO;
                if (element.isShow) {
                    if (element.position == APP_CONST.OTHER_POSITION) {
                        obj.programRole = element.otherPosition
                    } else {
                        obj.programRole = element.position
                    }
                    for (let i = 0; i < element.programs.length; i++) {
                        obj.programId = element.programs[i].id
                        if(this.addEditViewModeValue == APP_CONST.EDIT_MODE){
                        if( element.programs[i].userRoleId && element.programs[i].userRoleId.length){
                            obj.id = parseInt(element.programs[i].userRoleId)
                        }else{
                            obj.id = APP_CONST.ZERO;
                        }
                    }
                        this.user.userRoles.push(JSON.parse(JSON.stringify(obj)))
                    }
                } else {
                    if(this.addEditViewModeValue == APP_CONST.EDIT_MODE){
                        obj.id = element.id == APP_CONST.BLANK ? APP_CONST.ZERO :element.id;
                    }
                    obj.programRole = APP_CONST.BLANK;
                    obj.programId = APP_CONST.ZERO;
                    this.user.userRoles.push(JSON.parse(JSON.stringify(obj)))
                }
            }

            if (element.roleId == this.sessionAdminRole.id) {
                obj.accountId = element.accountId;
                obj.isPrimaryAccountAdmin = element.isPrimaryAccountAdmin;
                obj.roleId = element.roleId;
                obj.status = element.status;
                if (element.isShow) {
                    if (element.position == APP_CONST.OTHER_POSITION) {
                        obj.programRole = element.otherPosition
                    } else {
                        obj.programRole = element.position
                    }
                    obj.programId = element.programs[0].id
                    for (let i = 0; i < element.sites.length; i++) {
                        obj.siteId = element.sites[i].id
                        if (this.addEditViewModeValue == APP_CONST.EDIT_MODE){
                            if(element.sites[i].userRoleId && element.sites[i].userRoleId.length){
                                obj.id = parseInt(element.sites[i].userRoleId)
                            }else{
                                obj.id=APP_CONST.ZERO;
                            }
                        }
                        this.user.userRoles.push(JSON.parse(JSON.stringify(obj)))
                    }
                    if (element.addProgram && element.addProgram.length) {
                        for (let program = 0; program < element.addProgram.length; program++) {

                            obj.accountId = element.accountId;
                            obj.isPrimaryAccountAdmin = element.isPrimaryAccountAdmin;
                            obj.roleId = element.roleId;
                            obj.status = element.status;
                            if (element.addProgram[program].position == APP_CONST.OTHER_POSITION) {
                                obj.programRole = element.addProgram[program].otherPosition
                            } else {
                                obj.programRole = element.addProgram[program].position
                            }
                            obj.programId = element.addProgram[program].programs[0].id
                            for (let i = 0; i < element.addProgram[program].sites.length; i++) {
                                obj.siteId = element.addProgram[program].sites[i].id
                                if (this.addEditViewModeValue == APP_CONST.EDIT_MODE) {
                                    if (element.addProgram[program].sites[i].userRoleId && element.addProgram[program].sites[i].userRoleId.length) {
                                        obj.id = parseInt(element.addProgram[program].sites[i].userRoleId)
                                    } else {
                                        obj.id = APP_CONST.ZERO;
                                    }
                                }
                                this.user.userRoles.push(JSON.parse(JSON.stringify(obj)))
                            }
                        }
                    }
                } else {
                    if(this.addEditViewModeValue == APP_CONST.EDIT_MODE){
                        obj.id = element.id == APP_CONST.BLANK? APP_CONST.ZERO :element.id;
                    }
                    obj.programRole = APP_CONST.BLANK;
                    obj.programId = APP_CONST.ZERO;
                    obj.siteId = APP_CONST.ZERO;
                    this.user.userRoles.push(JSON.parse(JSON.stringify(obj)))
                }
            }

            if (element.roleId == this.staffRole.id) {
                obj.accountId = element.accountId;
                obj.isPrimaryAccountAdmin = element.isPrimaryAccountAdmin;
                obj.roleId = element.roleId;
                obj.status = element.status;
                if (element.isShow) {
                    if (element.position == APP_CONST.OTHER_POSITION) {
                        obj.programRole = element.otherPosition
                    } else {
                        obj.programRole = element.position
                    }
                    obj.programId = element.programs[0].id
                    for (let i = 0; i < element.sites.length; i++) {
                        obj.siteId = element.sites[i].id
                        if (this.addEditViewModeValue == APP_CONST.EDIT_MODE){
                            if(element.sites[i].userRoleId && element.sites[i].userRoleId.length){
                                obj.id = parseInt(element.sites[i].userRoleId)
                            }else{
                                obj.id=APP_CONST.ZERO;
                            }
                        }
                        this.user.userRoles.push(JSON.parse(JSON.stringify(obj)))
                    }
                    if (element.addProgram && element.addProgram.length) {
                        for (let program = 0; program < element.addProgram.length; program++) {

                            obj.accountId = element.accountId;
                            obj.isPrimaryAccountAdmin = element.isPrimaryAccountAdmin;
                            obj.roleId = element.roleId;
                            obj.status = element.status;
                            if (element.addProgram[program].position == APP_CONST.OTHER_POSITION) {
                                obj.programRole = element.addProgram[program].otherPosition
                            } else {
                                obj.programRole = element.addProgram[program].position
                            }
                            obj.programId = element.addProgram[program].programs[0].id
                            for (let i = 0; i < element.addProgram[program].sites.length; i++) {
                                obj.siteId = element.addProgram[program].sites[i].id
                                if (this.addEditViewModeValue == APP_CONST.EDIT_MODE) {
                                    if (element.addProgram[program].sites[i].userRoleId && element.addProgram[program].sites[i].userRoleId.length) {
                                        obj.id = parseInt(element.addProgram[program].sites[i].userRoleId)
                                    } else {
                                        obj.id = APP_CONST.ZERO;
                                    }
                                }
                                this.user.userRoles.push(JSON.parse(JSON.stringify(obj)))
                            }
                        }
                    }
                }
                else {
                    if(this.addEditViewModeValue == APP_CONST.EDIT_MODE){
                        obj.id = element.id == APP_CONST.BLANK ? APP_CONST.ZERO :element.id;
                    }
                    obj.programRole = APP_CONST.BLANK;
                    obj.programId = APP_CONST.ZERO;
                    obj.siteId = APP_CONST.ZERO;
                    this.user.userRoles.push(JSON.parse(JSON.stringify(obj)))
                }
            }

            if (element.roleId == this.noAccessRole.id) {
                obj.accountId = element.accountId;
                obj.isPrimaryAccountAdmin = element.isPrimaryAccountAdmin;
                obj.roleId = element.roleId;
                obj.status = element.status;
                if (element.isShow) {
                    if (element.position == APP_CONST.OTHER_POSITION) {
                        obj.programRole = element.otherPosition
                    } else {
                        obj.programRole = element.position
                    }
                    obj.programId = element.programs[0].id
                    for (let i = 0; i < element.sites.length; i++) {
                        obj.siteId = element.sites[i].id
                        if (this.addEditViewModeValue == APP_CONST.EDIT_MODE){
                        if(element.sites[i].userRoleId && element.sites[i].userRoleId.length){
                            obj.id = parseInt(element.sites[i].userRoleId)
                        }else{
                            obj.id=APP_CONST.ZERO;
                        }
                    }
                        this.user.userRoles.push(JSON.parse(JSON.stringify(obj)))
                    }

                    if (element.addProgram && element.addProgram.length) {
                        for (let program = 0; program < element.addProgram.length; program++) {

                            obj.accountId = element.accountId;
                            obj.isPrimaryAccountAdmin = element.isPrimaryAccountAdmin;
                            obj.roleId = element.roleId;
                            obj.status = element.status;
                            if (element.addProgram[program].position == APP_CONST.OTHER_POSITION) {
                                obj.programRole = element.addProgram[program].otherPosition
                            } else {
                                obj.programRole = element.addProgram[program].position
                            }
                            obj.programId = element.addProgram[program].programs[0].id
                            for (let i = 0; i < element.addProgram[program].sites.length; i++) {
                                obj.siteId = element.addProgram[program].sites[i].id
                                if (this.addEditViewModeValue == APP_CONST.EDIT_MODE) {
                                    if (element.addProgram[program].sites[i].userRoleId && element.addProgram[program].sites[i].userRoleId.length) {
                                        obj.id = parseInt(element.addProgram[program].sites[i].userRoleId)
                                    } else {
                                        obj.id = APP_CONST.ZERO;
                                    }
                                }
                                this.user.userRoles.push(JSON.parse(JSON.stringify(obj)))
                            }
                        }
                    }
                } else {
                    if(this.addEditViewModeValue == APP_CONST.EDIT_MODE){
                        obj.id = element.id == APP_CONST.BLANK? APP_CONST.ZERO :element.id;
                    }
                    obj.programRole = APP_CONST.BLANK;
                    obj.programId = APP_CONST.ZERO;
                    obj.siteId = APP_CONST.ZERO;
                    this.user.userRoles.push(JSON.parse(JSON.stringify(obj)))
                }
            }
        })
    }


    validateWhileAddingUser() {
        if(this.scheduleDate != APP_CONST.BLANK){
            this.user.scheduleDate = this.dateFormat(this.scheduleDate);
        }else{
            this.user.scheduleDate = APP_CONST.BLANK;
        }
        if(this.addEditViewModeValue=='edit' && this.revokeDate != APP_CONST.BLANK){
            this.user.inactiveDate = this.dateFormat(this.revokeDate);
        }else{
            this.user.inactiveDate = APP_CONST.BLANK;
        }
        if(this.addEditViewModeValue =='edit'&& this.user.inactiveDate == this.todayDate && this.user.status == APP_CONST.USER_STATUS_INACTIVE &&  this.user.inactiveDate == this.user.scheduleDate){
            this.user.scheduleDate = APP_CONST.BLANK;
        }else if(this.addEditViewModeValue=='edit'&& this.user.scheduleDate == this.todayDate && this.user.status == APP_CONST.STATUS_ACTIVE && this.user.inactiveDate == this.user.scheduleDate ){
            this.user.inactiveDate = APP_CONST.BLANK;
        }
        this.updateUserRoleStructure();
        let isValid = this.validate();
        if (isValid ) {
            this.createUser();
        }
    }

    scrollReset() {
        let elem: any = document.getElementById('edit-user') as HTMLElement;
        if(elem){
            elem.scrollTo(0, 0);
        }
    }

    close() {
        
        this.$emit(APP_CONST.CLOSE, 'add-update-user');
        this.refillForm();
        this.scrollReset();
        
    }

    refillForm(){
        this.selectedPhoneType = APP_CONST.BLANK;
        this.allProgramDataCount = 0;
        this.revokeDate=APP_CONST.BLANK
        this.revokeDateState=true
        this.user = JSON.parse(JSON.stringify(APP_CONST.USERS_CREATION));
        this.validatedFields = JSON.parse(JSON.stringify(APP_CONST.VALIDATE_USER_FORM));
        this.addedRoleList = [];
        this.validEmail = null;
        this.isApiError = false;
        this.scheduleDate=APP_CONST.BLANK;
        this.duplicateUser = false;
        this.allFieldRequired = false;
        this.isPhoneLengthValid = null;
        this.isAccountAdmin = false;
        this.isPrimaryAccountAdmin = false;
        this.isValid = true;
        this.apiResponseCode = 0;
        this.updateProgress = false;
        this.rolesList && this.rolesList.forEach((ele:any)=>{
            ele.isChecked = false;
            ele.disable = false;
        })
        this.allPrograms = this.getPrograms(this.accountId);
        this.openAddPrivilegesAccordion = false;
        this.enableAddPrivileges = false;
        this.userRole = [];
        this.roles = JSON.parse(JSON.stringify(APP_CONST.ROLE));
        this.roleObj = JSON.parse(JSON.stringify(APP_CONST.USERROLE_OBJ));
        this.addprogramObj=JSON.parse(JSON.stringify(APP_CONST.ADD_PROGRAM_OBJ));
        this.accountAdminSelected=false;
        this.unfilteredProgramList=[];
        this.programFullDataList=[];
        if(this.addEditViewModeValue == APP_CONST.EDIT_MODE){
            this.statusChangedTo=APP_CONST.ZERO;
            this.alreadyAddedInfo = false;
            this.scheduleDatecount=APP_CONST.ZERO
        }
        this.revokeDateError=APP_CONST.FALSE;
        this.scheduleDateError=APP_CONST.FALSE;
        this.isPrivilegePresent = APP_CONST.FALSE;
        this.firstTimeEdit = APP_CONST.FALSE;
        this.getGlobalState.mutateSelectedRecordsMultiSelect([]);
        this.getGlobalState.mutateFewDataChecked('');
        this.dropdownConfig = APP_CONST.USERS_DROPDOWN_CONFIG;
        this.singleDropdownConfig = APP_CONST.USERS_SINGLE_DROPDOWN_CONFIG;
        this.status = JSON.parse(JSON.stringify(APP_CONST.USER_STATUS));
    }


    selectType(type:string) {
        this.selectedPhoneType = type;
        if(this.selectedPhoneType == 'Select'){
            this.user.phoneType = APP_CONST.BLANK;
        }else{
            this.user.phoneType = this.selectedPhoneType;
        }
        
    }

    addPrivilege(){
        this.scheduleDatecount += APP_CONST.ONE;
        this.enableAddPrivileges = false;
        this.userRole.forEach((ele : IRoles)=>{
            ele.isShow = true;
        })
    }

    toggle(userRole: any) {
        userRole.accordOpen = !userRole.accordOpen;
        this.checkProgramValidations(userRole);
        this.checkSiteValidations(userRole)
    }

    checkProgramValidations(userRole:any){
        userRole.programError = false;  
        userRole.accordionError = false;
        if(userRole.programs && userRole.programs.length == APP_CONST.ZERO || userRole.programs && userRole.programs.length == APP_CONST.ONE && userRole.programs[0].id == APP_CONST.ZERO ) {
            userRole.programError = true;
            userRole.accordionError=true;
        }else{
            userRole.programError =  false;
            userRole.accordionError=false;
        }

        if(userRole.addProgram && userRole.addProgram.length){
            for(let user=0;user< userRole.addProgram.length;user++){
                if(userRole.addProgram[user].programs && userRole.addProgram[user].programs.length == APP_CONST.ZERO || userRole.addProgram[user].programs && userRole.addProgram[user].programs.length == APP_CONST.ONE && userRole.addProgram[user].programs[0].id == APP_CONST.ZERO ) {
                    userRole.addProgram[user].programError = true;
                    userRole.addProgram[user].accordionError=true;
                }
            }
        }

    }

    checkSiteValidations(userRole:any){
        if(userRole.toggleIndex > APP_CONST.ZERO){
        if (userRole.sites && userRole.sites.length == APP_CONST.ZERO || userRole.sites && userRole.sites.length == APP_CONST.ONE && userRole.sites[0].id == APP_CONST.ZERO) {
            userRole.siteError = true;
            userRole.accordionError=true;
        }else{
            userRole.siteError = false;
            userRole.accordionError=false;
        }

        if(userRole.addProgram && userRole.addProgram.length){
            for(let user=0;user< userRole.addProgram.length;user++){
            if(userRole.addProgram[user].sites && userRole.addProgram[user].sites.length == APP_CONST.ZERO || userRole.addProgram[user].sites && userRole.addProgram[user].sites.length == APP_CONST.ONE && userRole.addProgram[user].sites[0].id == APP_CONST.ZERO ) {
                userRole.addProgram[user].siteError = true;
                userRole.addProgram[user].accordionError=true;
            }
        }
    }
    }
    }

    checkAccordionError(userRole:any){
        let errorCheck:boolean=false;
        if(userRole.addProgram && userRole.addProgram.length){
            for(let user=0;user< userRole.addProgram.length;user++){
                if(userRole.addProgram[user].accordionError){
                    errorCheck = true
                }else{
                    userRole.accordionError = false;
                    errorCheck = false
                }
            }
        }
        return (userRole.accordionError || errorCheck || (userRole.programError && !userRole.programs.length) || (userRole.siteError && !userRole.sites.length));
    }
      

    selectPosition(userRole: any,position:string){
        if(position == APP_CONST.SELECT_OBJECT.name){
            userRole.position = APP_CONST.BLANK;
        }else{
            userRole.position = position;
        }
        if(userRole.position == 'Other'){
            userRole.otherPositionShow = true;
        }else{
            userRole.otherPositionShow = false;
        }
    }

    closeAccordion(userRole:any){
        if(userRole.roleId == this.programAdminRole.id){
            this.allPrograms.forEach((element:any) => {
                element.checked = false;
            });       
        }
        if(userRole.roleId == this.sessionAdminRole.id||userRole.roleId == this.staffRole.id||userRole.roleId == this.noAccessRole.id){
            userRole.addProgram && userRole.addProgram.forEach((ele:any)=>{
                    ele.programs=[];
                    if(ele.sites){
                        ele.sites=[];
                    }
                })
     
        }       
        this.selectProgram(userRole,{id:APP_CONST.ZERO,value:APP_CONST.SELECT_OBJECT.name})
        userRole.isShow = false;
        userRole.position=APP_CONST.BLANK;
        userRole.otherPosition=APP_CONST.BLANK;
        userRole.programs=[];
        userRole.addProgram =[];
        userRole.otherPositionShow=false;
        userRole.addProgramIsShow=false;
        userRole.programError=false;
        userRole.siteError=false;
        userRole.accordionError=false;
        userRole.allProgramsSelected = false;
        userRole.allSiteSelected = false
        userRole.positionDropdownState = false;
        userRole.accordOpen=true;
        userRole.toggleIndex = APP_CONST.ONE;
        userRole.accordionError = false;
        userRole.allMandatoryFieldsFilled=true;
        if(userRole.sites){
        userRole.sites=[];
        userRole.totalSites=[];
        }
        this.enableAddPrivileges = true;
        if(this.addEditViewModeValue == APP_CONST.EDIT_MODE){
            this.scheduleDatecount += APP_CONST.ONE;
            userRole.scheduleDateDisableState = true;
            userRole.newPrivilegesAdded = [];
            this.getDateState();
    }
    }

    addProgram(userRole:any){
        userRole.addProgramIsShow=true;
        if(this.addEditViewModeValue == APP_CONST.EDIT_MODE){
            this.addprogramObj.roleId = userRole.roleId;
            this.addprogramObj.roleName = userRole.roleName;
        }
        userRole.addProgram.push(JSON.parse(JSON.stringify(this.addprogramObj)));
    }

    closeAccordionProgram(userRole:any,index:number,userRoleProgram:any){
        if(userRole.roleId == this.sessionAdminRole.id||this.staffRole.id||this.noAccessRole.id){
            if(userRoleProgram && userRoleProgram.programs){
                userRoleProgram.programs=[];
            }
            if(userRoleProgram && userRoleProgram.sites){
                userRoleProgram.sites=[];
            }
        }  
        userRole.addProgram.splice(index,1)
        userRoleProgram = JSON.parse(JSON.stringify(APP_CONST.ADD_PROGRAM_OBJ));
        this.selectProgram(userRoleProgram,{id:APP_CONST.ZERO,value:APP_CONST.SELECT_OBJECT.name})
        if(this.addEditViewModeValue == APP_CONST.EDIT_MODE){
            this.scheduleDatecount += APP_CONST.ONE;
            userRole.newPrivilegesAdded = [];
            userRole.scheduleDateDisableState = true;
            this.getDateState();
        }
    }

    getPrograms(accountId: number){
        if(this.allProgramDataCount == APP_CONST.ZERO){
            this.allProgramDataCount +=1 ;
            getProgramByAccount(accountId).then((res:any) => {
                if(res.status === APP_CONST.RESPONSE_200){
                    this.allPrograms = [];
                    const data = res.data;
                    this.programFullDataList = data.filter((program: any) => {
                        return program.status === APP_CONST.ONE;
                    }) as IProgram[];
                    this.programFullDataList.forEach((program: any) => {
                        program[APP_CONST.IS_CHECKED] = false;
                    });
                    this.programFullDataList.forEach((data:any)=>{
                        this.allPrograms.push({id:data.id, value:data.name, checked:data.isChecked})
                       })   
                    this.unfilteredProgramList = this.allPrograms;     
                } else {
                    this.allPrograms = [];
                }
            })
        }
    }


    selectPrograms(program:any,userRole:any){
        if(!program.selectedValue){
            return
        }
        this.alreadyAddedInfo = APP_CONST.FALSE;
        program.dropdownList.forEach((val:any) => {
            this.allPrograms.forEach((prog:any) => {
              if(prog.id==val.id){
                prog.checked = val.checked;
              }
            });
        });
        if(!program.isAllSelect){
        let addedProgramIndex = userRole.programs.findIndex((ele: any) => ele.id == program.selectedValue.id)
        if (program.selectedValue.checked && !userRole.programs.includes(program.selectedValue.id)) {
            userRole.programs.push({name:program.selectedValue.name,id:program.selectedValue.id})
        } else if (!program.selectedValue.checked && addedProgramIndex > -1) {    
            userRole.programs.splice(addedProgramIndex, 1)
        }
        if(userRole.toggleIndex > APP_CONST.ZERO){
            this.checkProgramValidations(userRole);
        }
        this.checkSelectionState(userRole);
        if(this.addEditViewModeValue == APP_CONST.EDIT_MODE){
            this.viewUserDetailsData.completeResponse.systemRoleList.forEach((element:any)=>{
                if(program.selectedValue.checked == APP_CONST.TRUE && element.programId == program.selectedValue.id  && element.siteId == APP_CONST.ZERO && element.name == userRole.roleName || program.selectedValue.checked == APP_CONST.FALSE &&  element.programId == program.selectedValue.id && element.siteId == APP_CONST.ZERO){
                    this.alreadyAddedInfo = true;
                }
            })
            this.filterNewProgramPrivileges(userRole,program.selectedValue);
            this.checkDateStateDuringEdit(userRole);
        }
        }else{
            if(program.dropdownList && program.dropdownList[0]){
                this.selectAllPrograms(userRole,program.dropdownList[0].checked)
            }
         
        }
        
    }


    filterNewProgramPrivileges(userRole: any, program:any) {
        if (!this.alreadyAddedInfo && program.checked) {
            userRole.newPrivilegesAdded.push(program.id);
        } else if(!this.alreadyAddedInfo && !program.checked) {
            let index = userRole.newPrivilegesAdded.findIndex((privilege: number) => privilege == program.id);
            if (index > APP_CONST.MINUS_ONE) {
                userRole.newPrivilegesAdded.splice(index, APP_CONST.ONE)
            }
        }
    }

    filterNewSitePrivileges(userRole: any, site:any) {
        if (!this.alreadyAddedInfo && site.checked) {
            userRole.newPrivilegesAdded.push(site.id);
        } else if (!this.alreadyAddedInfo && !site.checked) {
            let index:number;
            index = userRole.newPrivilegesAdded && userRole.newPrivilegesAdded.findIndex((sites:number)=> sites == site.id);
            if (index > APP_CONST.MINUS_ONE) {
                userRole.newPrivilegesAdded.splice(index, APP_CONST.ONE)
            }
        }
    }

    selectAllPrograms(userRole:any,allSelected:boolean){
        this.alreadyAddedInfo = false;
        userRole.programs = []
        let programsArray:any = [];
        let selectedValue = allSelected;
        this.allPrograms.forEach((program: any) => {
                program.checked = selectedValue
        });
        this.allPrograms = JSON.parse(JSON.stringify(this.allPrograms))
        programsArray = this.allPrograms;
        this.checkSelectionState(userRole)
        let array:Array<number> = [];
        for (let i = 0; i < programsArray.length; i++) {
            if (selectedValue) {
                userRole.programs.push({ name: programsArray[i].name, id: programsArray[i].id })
                array.push(programsArray[i].id);
            } else {
                userRole.programs = []
            }
        }
        if (userRole.toggleIndex > APP_CONST.ZERO) {
            this.checkProgramValidations(userRole);
        }
        if (this.addEditViewModeValue == APP_CONST.EDIT_MODE){
            const programsArray = array.filter((el: any) => {
                return !this.viewUserDetailsData.completeResponse.systemRoleList.find((element: any) => {
                    return element.programId === el;
                });
            });
            if (programsArray.length == 0 && userRole.allProgramsSelected || !userRole.allProgramsSelected) {
                this.alreadyAddedInfo = APP_CONST.FALSE;
                userRole.newPrivilegesAdded = [];
            } else if (programsArray.length != 0) {
                this.alreadyAddedInfo = false;
                programsArray.forEach((element: number) => {userRole.newPrivilegesAdded.push(element) })
            }
            this.checkDateStateDuringEdit(userRole);
        } 
      }

      checkSelectionState(userRole:any){
        const selectedProgramCount = this.allPrograms && this.allPrograms.filter((prog: any) => prog.checked);
        if(selectedProgramCount && selectedProgramCount.length >= 0 && (selectedProgramCount.length !== this.allPrograms.length)) {
          userRole.allProgramsSelected = false
      }else{
        userRole.allProgramsSelected = true
        }
      }

    selectProgram(userRole:any,program:{id:number,value:string}){
        userRole.totalSites=[];
        userRole.sites=[];
        userRole.programs = [];
        userRole.allSiteSelected=false;
        userRole.programs.push({name:program.value,id:program.id})
        userRole.programsDrop = false;
        if(program.id != APP_CONST.ZERO){
            userRole.siteError = false;
            this.getSite(userRole,-1,-1);
         }
         if(userRole.toggleIndex > APP_CONST.ZERO){
         this.checkProgramValidations(userRole);
         }        
       
        }

    
 
    

     getSite(userRole: any, roleIndex:number, programIndex:number){
         getSiteByProgramId(userRole.programs[0].id).then((res:any) => {
             if(res.status === APP_CONST.RESPONSE_200){
                const data = res.data;
                this.siteList = data; 
                this.siteList = data.filter((site: any) => {
                    return site.status === 1;
                })
                this.siteList.forEach((site:any) => {
                    site[APP_CONST.IS_CHECKED] = false;
                })
                    let roleInd :number=0;
                    if(userRole.roleId){
                        roleInd= this.userRole.map(e => e.roleId).indexOf(userRole.roleId);
                    }
                    else{
                        roleInd=roleIndex;
                    }
                if(roleIndex>-1 && programIndex>-1)
                {
                    this.userRole[roleInd].addProgram[programIndex].totalSites = this.siteList;
                    this.userRole[roleInd].addProgram[programIndex].sites && this.userRole[roleInd].addProgram[programIndex].sites.forEach((site: any) => {
                        let siteIndex = this.userRole[roleInd].addProgram[programIndex].totalSites && this.userRole[roleInd].addProgram[programIndex].totalSites.findIndex((ele: any) => ele.siteId == site.id)
                        if (siteIndex > APP_CONST.MINUS_ONE) {
                            this.userRole[roleInd].addProgram[programIndex].totalSites[siteIndex].isChecked = true;
                        }
                    })
                    this.checkSiteSelectionState(this.userRole[roleInd].addProgram[programIndex])
                }
                else if(roleIndex>-1)
                {
                    this.userRole[roleInd].totalSites = this.siteList;
                    this.userRole[roleInd].sites && this.userRole[roleInd].sites.forEach((site: any) => {
                        let siteIndex = this.userRole[roleInd].totalSites && this.userRole[roleInd].totalSites.findIndex((element: any) => element.siteId == site.id)
                        if (siteIndex > APP_CONST.MINUS_ONE) {
                            this.userRole[roleInd].totalSites[siteIndex].isChecked = true;
                        }
                    })
                    this.checkSiteSelectionState(this.userRole[roleInd])
                }
                userRole.totalSites = this.siteList;
            } else {
                this.siteList = [];
                userRole.totalSites = this.siteList;
            }
        })
    }

    selectSites(site:any,userRole:any){
        if(!site.selectedValue){
            return
        }
        this.alreadyAddedInfo = APP_CONST.FALSE;
        site.dropdownList.forEach((val:any) => {
            userRole.totalSites.forEach((sites:any) => {
              if(sites.siteId==val.id){
                sites.isChecked = val.checked;
              }
            });
        });
        if(!site.isAllSelect){
        let addedSiteIndex = userRole.sites.findIndex((ele: any) => ele.id == site.selectedValue.id)
        if (site.selectedValue.checked && !userRole.sites.includes(site.selectedValue.id)) {
            userRole.sites.push({name:site.selectedValue.name,id:site.selectedValue.id})
        } else if (!site.selectedValue.checked && addedSiteIndex > -1) {    
            userRole.sites.splice(addedSiteIndex, 1)
        }
        if(userRole.toggleIndex > APP_CONST.ZERO){
            this.checkSiteValidations(userRole);
        }
        this.checkSiteSelectionState(userRole)
        if(this.addEditViewModeValue == APP_CONST.EDIT_MODE){
            this.viewUserDetailsData.completeResponse.systemRoleList.forEach((element: any) => {
                if (site.selectedValue.checked == APP_CONST.TRUE  && element.siteId == site.selectedValue.id && element.name == userRole.roleName || site.selectedValue.checked == APP_CONST.FALSE && element.siteId == site.selectedValue.id && element.name == userRole.roleName ) {
                    this.alreadyAddedInfo = true;
                }
            })
            this.filterNewSitePrivileges(userRole,site.selectedValue);
        this.checkDateStateDuringEdit(userRole);
        }  
    }else{
        if(site.dropdownList && site.dropdownList[0]){
            this.selectAllSites(userRole,site.dropdownList[0].checked)
        }
       
       } 
    }

    checkDateStateDuringEdit(userRoleObject:any){
        userRoleObject.scheduleDateDisableState = true;
        this.scheduleDatecount += APP_CONST.ONE;
        if (!this.alreadyAddedInfo && userRoleObject.newPrivilegesAdded && userRoleObject.newPrivilegesAdded.length && this.user.status == APP_CONST.STATUS_ACTIVE && (userRoleObject.roleId != this.programAdminRole.id && userRoleObject.programs.length && userRoleObject.sites.length ||userRoleObject.programs.length && userRoleObject.roleId == this.programAdminRole.id )) {
            userRoleObject.scheduleDateDisableState = false;  
        }
        if (!this.alreadyAddedInfo && userRoleObject.newPrivilegesAdded && userRoleObject.newPrivilegesAdded.length) {
            this.firstTimeEdit = false;
            this.getDateState();
        } else if (!this.alreadyAddedInfo && userRoleObject.newPrivilegesAdded && userRoleObject.newPrivilegesAdded.length == APP_CONST.ZERO) {
            this.scheduleDateDisable = true;
                let dateScheduled = this.viewUserDetailsData && this.viewUserDetailsData.invitationDate && this.viewUserDetailsData.invitationDate.length ? this.viewUserDetailsData.invitationDate :APP_CONST.BLANK;
                setTimeout(()=>{
                    let noPrivilege:boolean = false;
                    let lengthRole:number = 0;
                    lengthRole = this.userRole.filter(role => role.isShow).length
                    noPrivilege = this.userRole && Object.keys(this.userRole).every((element:any) => !this.userRole[element].isShow) || (lengthRole == APP_CONST.ONE && !Object.keys(this.userRole).every((element:any) => this.userRole[element].allMandatoryFieldsFilled) || !Object.keys(this.userRole).every((element:any) => this.userRole[element].allMandatoryFieldsFilled));
                    dateScheduled > this.todayDate && this.user.status == APP_CONST.STATUS_ACTIVE && this.statusChangedTo != APP_CONST.STATUS_ACTIVE ? this.futureDateState() :
                    this.scheduleDate = this.statusChangedTo == APP_CONST.STATUS_ACTIVE ? noPrivilege? APP_CONST.BLANK: this.todayDate : this.viewUserDetailsData.invitationDate;
                  },10)     
        }
    }

    selectAllSites(userRole:any,allSelected:boolean){
        this.alreadyAddedInfo = false;
        userRole.sites = []
        let selectedValue=allSelected;
        userRole.totalSites.forEach((site:any) => {
            site.isChecked=selectedValue
        });
        userRole.totalSites=JSON.parse(JSON.stringify(userRole.totalSites))
        this.checkSiteSelectionState(userRole);  
        let array:Array<number> = [];
        for(let i=0 ; i<userRole.totalSites.length ; i++) {
        if(selectedValue){
          userRole.sites.push({name: userRole.totalSites[i].siteName,id:userRole.totalSites[i].siteId})
          if (this.addEditViewModeValue == APP_CONST.EDIT_MODE) {
            array.push(userRole.totalSites[i].siteId);
        }   
        }else{
          userRole.sites = []
        }
        if(userRole.toggleIndex > APP_CONST.ZERO){
            this.checkSiteValidations(userRole);
        }
      }

        if (this.addEditViewModeValue == APP_CONST.EDIT_MODE) {
            const siteArray = array.filter((el: any) => {
                return !this.viewUserDetailsData.completeResponse.systemRoleList.find((element: any) => {
                    return element.siteId === el && element.name == userRole.roleName;
                });
            });
            if (siteArray.length == 0 && userRole.allSiteSelected || !userRole.allSiteSelected) {
                this.alreadyAddedInfo = false;
                userRole.newPrivilegesAdded = [];
            } else if (siteArray.length != 0) {
                this.alreadyAddedInfo = false;
                siteArray.forEach((element: number) => { userRole.newPrivilegesAdded.push(element) })
            }
            this.checkDateStateDuringEdit(userRole);
        } 
      }

      checkSiteSelectionState(userRole: any){
        const selectedSiteCount = userRole.totalSites.filter((site: any) => site.isChecked);
        if(selectedSiteCount.length >= 0 && (selectedSiteCount.length !== userRole.totalSites.length)) {
          userRole.allSiteSelected = false
      }else{
        userRole.allSiteSelected = true
        }
      }

      

    editUserDetails(){
        programList.mutateAddEditViewMode(APP_CONST.EDIT_MODE)
        this.firstTimeEdit = true;
        if(this.viewUserDetailsData){
            this.user.firstName = this.viewUserDetailsData.firstName
            this.user.lastName = this.viewUserDetailsData.lastName
            this.user.email = this.viewUserDetailsData.email
            this.user.phoneNumber = this.viewUserDetailsData.completeResponse && this.viewUserDetailsData.completeResponse.phoneNumber
            this.selectedPhoneType = this.viewUserDetailsData.completeResponse && this.viewUserDetailsData.completeResponse.phoneType;
            this.user.phoneType = this.viewUserDetailsData.completeResponse && this.viewUserDetailsData.completeResponse.phoneType;
            this.user.status = this.viewUserDetailsData.completeResponse && this.viewUserDetailsData.completeResponse.status;
            this.user.id = this.viewUserDetailsData.completeResponse && this.viewUserDetailsData.completeResponse.userId;
            this.user.personalInvitationNote = this.viewUserDetailsData.completeResponse && this.viewUserDetailsData.completeResponse.personalInvitationNote
            this.status.value = this.user.status == APP_CONST.STATUS_ACTIVE ? APP_CONST.ACTIVE : APP_CONST.INACTIVE;
            this.status.id = this.user.status;
            this.rolesDuringEdit()
            this.getRevokeDate();
            this.getDateState();
    }
    }

    getRevokeDate(){
        if( this.viewUserDetailsData && this.viewUserDetailsData.completeResponse.revokeDate){
            this.revokeDate=this.viewUserDetailsData.completeResponse.revokeDate
            let revoke = this.viewUserDetailsData.completeResponse.revokeDate
            if(revoke > this.todayDate){
                this.revokeDate=this.viewUserDetailsData.completeResponse.revokeDate
                this.revokeDateState = false      
            }else{
                this.revokeDateState = true
            }
              
        }else{
            this.revokeDate = APP_CONST.BLANK;
        }
    }

    rolesDuringEdit(){
        if(this.viewUserDetailsData){
            this.addedRoleList = JSON.parse(JSON.stringify(this.viewUserDetailsData.allUniqueRolesArray));
        }
        this.getRoleList()
        this.rolesList.forEach((role)=>{
            if(this.viewUserDetailsData && this.viewUserDetailsData.allUniqueRolesArray.includes(role.name)){
                role.isChecked=true
            }
        })
        if (this.currentRoleId <= this.accountAdminId) {
            if(this.viewUserDetailsData && (this.viewUserDetailsData.allUniqueRolesArray.includes(this.sessionAdmin) || this.viewUserDetailsData.allUniqueRolesArray.includes(this.staff) || this.viewUserDetailsData.allUniqueRolesArray.includes(this.noAccess))){
                this.rolesList.forEach((role)=>{
                    if(role.id== this.accountAdminId){
                        role.disable=true
                    }
                })
            }
            if(this.viewUserDetailsData && (this.viewUserDetailsData.allUniqueRolesArray.includes(this.accountAdmin) || this.viewUserDetailsData.allUniqueRolesArray.includes(this.primaryAccountAdmin))){
                this.rolesList.forEach((role)=>{
                    if(role.id== this.sessionAdminRole.id || role.id==this.staffRole.id || role.id== this.noAccessRole.id){
                        role.disable=true
                    }
                })
            }
        }
        this.updateStructure();
    }

    selectStatus(status: {id: number, value: string}){
        this.scheduleDateError = APP_CONST.FALSE;
        this.revokeDateError = APP_CONST.FALSE;
        if(status.id != this.user.status){
            this.status.id = status.id;
            this.status.value = status.value;
            this.user.status = status.id;
        if(status.id == APP_CONST.TWO){
            this.revokeDate = this.todayDate;
            this.revokeDateState = true;
            this.scheduleDate = APP_CONST.BLANK;
            this.scheduleDateDisable = true;
            this.statusChangedTo = APP_CONST.USER_STATUS_INACTIVE;
            
        }else if(status.id == APP_CONST.ONE){
            this.scheduleDatecount += APP_CONST.ONE;
            this.scheduleDate = this.todayDate;
            this.scheduleDateDisable = true;
            this.statusChangedTo = APP_CONST.STATUS_ACTIVE
            this.revokeDate = APP_CONST.BLANK;
        }
    }
    }

    getDateState() { 
        if (this.viewUserDetailsData && this.viewUserDetailsData && this.viewUserDetailsData.invitationDate && this.firstTimeEdit == true) {
            this.scheduleDateDisable = true;
            this.editScheduleDate();
        } else if(this.scheduleDatecount > 0) {
            let dateScheduled = this.viewUserDetailsData && this.viewUserDetailsData.invitationDate
            if(dateScheduled > this.todayDate && this.user.status == APP_CONST.STATUS_ACTIVE && this.statusChangedTo != APP_CONST.STATUS_ACTIVE){
                this.futureDateState();
            }else{
                this.scheduleDateStateDuringEdit();
            }
        } 
    }

    futureDateState(){
        this.scheduleDateDisable = true;
        this.userRole.forEach((element: any) => {
            if (element.roleId == this.accountAdminRole.id) {
                this.scheduleDateDisable = false;
            }
            this.checkForDisableState(element);
        });

        this.statusChangedTo == APP_CONST.STATUS_ACTIVE
        if (this.scheduleDateDisable) {    
            setTimeout(()=>{
            let noPrivilege:boolean = false;
            let lengthRole:number = 0;
            lengthRole = this.userRole.filter(role => role.isShow).length
            noPrivilege = this.userRole && Object.keys(this.userRole).every((element:any) => !this.userRole[element].isShow) || (lengthRole == APP_CONST.ONE && !Object.keys(this.userRole).every((element:any) => this.userRole[element].allMandatoryFieldsFilled) || !Object.keys(this.userRole).every((element:any) => this.userRole[element].allMandatoryFieldsFilled));
            this.scheduleDate = this.statusChangedTo == APP_CONST.STATUS_ACTIVE ? noPrivilege? APP_CONST.BLANK: this.todayDate : APP_CONST.BLANK;
            },10)
        }else{
            this.scheduleDate = this.statusChangedTo == APP_CONST.STATUS_ACTIVE ? APP_CONST.BLANK : this.scheduleDate;
        }
    }

    scheduleDateStateDuringEdit(){
    if(!this.scheduleDateDisable){
        this.alreadyEnableScheduleDate = true;
    }else{
        this.alreadyEnableScheduleDate = false;
    }
    this.scheduleDateDisable = true;
   
    this.userRole.forEach((element: any) => {
        if (element.roleId == this.accountAdminRole.id && this.viewUserDetailsData &&!this.viewUserDetailsData.allUniqueRolesArray.includes(this.accountAdminRole.name) && !this.viewUserDetailsData.allUniqueRolesArray.includes(this.primaryAccountAdminRole.name)) {
            this.scheduleDateDisable = false;
       } 
        if (element.hasOwnProperty('scheduleDateDisableState') && !element.scheduleDateDisableState && element.isShow && element.newPrivilegesAdded && element.newPrivilegesAdded.length ){
            this.checkForDisableState(element);
        }

        element.addProgram && element.addProgram.forEach((ele: any) => {
            if (ele.hasOwnProperty('scheduleDateDisableState') && !ele.scheduleDateDisableState) {
                if (ele.programs && ele.programs.length) {
                    if (ele.sites && ele.sites.length) {
                        this.scheduleDateDisable = false;
                    }
                }
            }
        })
     
    })


        if (!this.scheduleDateDisable && !this.alreadyEnableScheduleDate) {
            this.scheduleDate =APP_CONST.BLANK;
        } else if(this.scheduleDateDisable) {
            setTimeout(()=>{
                let noPrivilege:boolean = false;
                let lengthRole:number = 0;
                lengthRole = this.userRole.filter(role => role.isShow).length
                noPrivilege = this.userRole && Object.keys(this.userRole).every((element:any) => !this.userRole[element].isShow) || (lengthRole == APP_CONST.ONE && !Object.keys(this.userRole).every((element:any) => this.userRole[element].allMandatoryFieldsFilled) || !Object.keys(this.userRole).every((element:any) => this.userRole[element].allMandatoryFieldsFilled));
                this.scheduleDate = this.statusChangedTo == APP_CONST.STATUS_ACTIVE ? noPrivilege? APP_CONST.BLANK : this.todayDate  : this.viewUserDetailsData.invitationDate;
                if(!this.viewUserDetailsData.invitationDate){
                    this.scheduleDate = APP_CONST.BLANK;
                }
            },10);
        }
    }

    editScheduleDate(){
        this.scheduleDate = this.viewUserDetailsData && this.viewUserDetailsData.invitationDate
        let dateScheduled = this.viewUserDetailsData && this.viewUserDetailsData.invitationDate
        if (dateScheduled > this.todayDate) {
            this.scheduleDateDisable = false
        } else if (dateScheduled <= this.todayDate || dateScheduled == APP_CONST.BLANK) {
            this.scheduleDateDisable = true
        }
    }

}
