import tableStore from '@/store/modules/table';
import { Component, Prop, Vue } from 'vue-property-decorator';
import APP_CONST from '@/constants/AppConst';


@Component({
   components: {
   }
})
export default class SortingSearchingActions extends Vue {
   filterValue: string = '';

   @Prop()
   header!: any;

   @Prop()
   payload!: any

   @Prop()
   search!: any

   @Prop()
   componentName!: string

   @Prop({ default: function () { return [] } })
   searchLists!: { header: string, values: any[] }[];

   get searchInputValues() {
      return tableStore.searchInputValues;
   }

   beforeMount() {
      if (this.header && this.search[this.header.value]) {
         let searchInputValues: any = { key: this.header.value, value: this.search[this.header.value] }
         tableStore.updatecommonSearchArray({ ...this.searchInputValues, [this.componentName]: searchInputValues })
      }
      this.updateSearchValue();
   }

   destroyed() {
      if(this.payload && (this.payload.searchField || this.payload.SearchField)){
         if (this.searchInputValues[this.componentName] && this.header && this.header.value == this.searchInputValues[this.componentName].key) {
            this.search[this.header.value] = this.searchInputValues[this.componentName].value
         }
      }
   }

   updateSearchValue() {
      this.filterValue = (this.payload && (this.payload.searchField || this.payload.SearchField)) ? this.header && this.search[this.header.value] : '';
      if (this.header && this.payload && this.payload.isExactMatch) {
         this.search[this.header.value] = '';
      }
   }

   closePanel() {
      this.$emit('closeActionsPanel', this.header)
   }

   sortData(sortField: string, sortDirection: number) {
      this.$emit("sortData", { sortField: sortField, sortDirection: sortDirection });
   }

   searchData(searchField: string, fromList?: boolean, fromClearSelection?: boolean) {
      for (var key in this.search) {
         if (this.search.hasOwnProperty(key) && key !== searchField) {
            this.search[key] = "";
         }
      }
      if (this.payload && !fromList) {
         this.payload.isExactMatch = false;
      }
      if (searchField !== '') {
         this.closePanel;
      }
      let searchInputValues = this.header && { key: this.header.value, value: this.search[this.header.value] }
      tableStore.updatecommonSearchArray({ ...this.searchInputValues, [this.componentName]: searchInputValues })
      this.$emit('searchData', { searchField: searchField, isExactMatch: this.payload && this.payload.isExactMatch }, fromClearSelection ? fromClearSelection : false);
   }

   resetSearch(field: string) {
      if (!this.search[field].length) {
         this.searchData('');
      }
   }

   clearSelection() {
      this.payload.isExactMatch = false;
      this.filterValue = '';
      this.searchData('', false, true);
   }

   get filteredListValues() {
      let openDropDownSearchListIdx = this.searchLists.findIndex((list: any) => {
         return list.header == this.header.value;
      });
      if (this.payload && this.payload.isExactMatch && openDropDownSearchListIdx >= APP_CONST.ZERO) {
         let selectElement: any = this.searchLists[openDropDownSearchListIdx].values.filter((value: any) => { return value == this.payload.search });
         let unselectElement: any = []
         this.searchLists[openDropDownSearchListIdx].values.forEach((value: any) => {
            if (value != this.payload.search) {
               unselectElement.push(value)
            };
         })
         let searchInputValues = unselectElement.filter((el: string) => {
            return `${el}`.toLowerCase().includes(this.search[this.header.value].toLowerCase())
         })
         return selectElement.concat(searchInputValues);
      }
      else {
         if (openDropDownSearchListIdx >= APP_CONST.ZERO) {
            return this.searchLists[openDropDownSearchListIdx].values.filter((el: string) => {
               return `${el}`.toLowerCase().includes(this.search[this.header.value].toLowerCase())
            })
         }
      }
   }

   searchFromList(searchField: string, searchValue: string) {
      this.payload.isExactMatch = true;
      this.search[searchField] = searchValue;
      this.searchData(searchField, this.payload.isExactMatch);
   }
}