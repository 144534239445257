
import { Component, Prop, Vue } from 'vue-property-decorator';

export interface ActionMenuButtonProps {
  label: string;
  value: string;
}

@Component({ inheritAttrs: false })
export default class ActionMenuButton extends Vue {
  @Prop() actionsMenu!: ActionMenuButtonProps[];
  @Prop() toggleDropDown!: boolean;

  handleOutsideClick() {
    this.$emit('clickOutside', true);
  }

  handleClick(value: string) {
    this.$emit('click', value);
  }
}
