
































import {PrimaryAccountAdminAlert}  from "@/popupcomponents/primaryAccountAdminAlert/PrimaryAccountAdminAlert";
export default PrimaryAccountAdminAlert;
