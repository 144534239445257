var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"uk-flex-top popupwrapper",attrs:{"id":"add-privileges-popup","uk-modal":"esc-close:false;bg-close:false","container":_vm.component}},[_c('div',{staticClass:"uk-modal-dialog uk-margin-auto-vertical popupbox"},[_vm._m(0),_c('div',{staticClass:"popup-header blueTheme"},[_c('button',{staticClass:"cross-icon uk-modal-close",attrs:{"type":"button","bx-attr":"submissions-navigation-close-popup"},on:{"click":function($event){return _vm.close()}}},[_c('img',{attrs:{"src":require("@/assets/images/closeIconBlack.svg"),"alt":"desktop-close-icon"}})]),_c('h1',[_vm._v(_vm._s(_vm.getScreenText("ADD_PRIVILEGES")))])]),_c('div',{staticClass:"site-content-box",attrs:{"tabIndex":"0"}},[_c('div',{staticClass:"site-content-inner"},[_c('div',{staticClass:"select"},[_c('span',{staticClass:"select-program-site"},[_vm._v(" "+_vm._s(_vm.getScreenText("SELECT_THE_PROGRAM_AND_SITE_SESSION"))+" ")])]),_c('div',{staticClass:"add-users"},[_c('span',{staticClass:"selected"},[_vm._v(_vm._s(_vm.getScreenText("SELECTED_USERS_ADD_PRIVILEGES"))),_c('span',{staticClass:"text-style-1"},[_vm._v(_vm._s(_vm.add_users && _vm.add_users.length))])]),_c('span',{staticClass:"selected"},[_vm._v(_vm._s(_vm.getScreenText("SELECTED_ROLE"))+" "),_c('span',{staticClass:"text-style-1"},[_vm._v(_vm._s(_vm.role))])])]),(_vm.role == 'program admin')?_c('div',{staticClass:"form-groupfullwidth"},[_c('multi-select-dropdown',{attrs:{"tabindex":"-1","dropdown":_vm.multiProgramDropdown,"bx-attr":"multiprogramDropdown"},on:{"selectionRecords":_vm.multiProgramSelection}})],1):_vm._e(),(_vm.role == 'session admin' || _vm.role == 'staff' || _vm.role == 'no access'
                )?_c('div',{staticClass:"form-groupfullwidth"},[_c('dropdown',{attrs:{"classes":['filter-select-dropdown program-select-dropdown'],"dropdown":Object.assign({}, _vm.singleProgramDropdown, {value: _vm.selectedProgram.value ? _vm.selectedProgram.value : 'Select', firstSelectLabel: _vm.selectedProgram.value ? _vm.selectedProgram.value : 'Select', id: _vm.selectedProgram.id}),"bx-attr":"singleprogramDropdown"},on:{"onSelectionChange":_vm.singleProgramSelection}})],1):_vm._e(),(_vm.role == 'session admin' || _vm.role == 'staff' || _vm.role == 'no access'
                )?_c('div',{staticClass:"form-groupfullwidth"},[_c('multi-select-dropdown',{attrs:{"dropdown":_vm.multiSiteDropdown,"bx-attr":"multisiteDropdown"},on:{"selectionRecords":_vm.multiSiteSelection}})],1):_vm._e(),_c('div',{staticClass:"send-ivite"},[_c('span',{staticClass:"Transportation-budge"},[_vm._v(" "+_vm._s(_vm.getScreenText("INVITE_DATE"))),(_vm.role != 'no access')?_c('span',{staticClass:"text-style-1"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"calender",class:_vm.role == 'no access' ? 'disable-datePicker' : ''},[_c('datepicker',{attrs:{"bx-attr":"inactive-date-input","toDate":new Date().setDate(new Date().getDate() - 1),"date":_vm.scheduleDate,"openDate":_vm.scheduleDate != '' ? _vm.scheduleDate : new Date(),"inputClass":_vm.role == 'no access' ? 'disable' : ''},on:{"onDateChange":function (e) {
                      _vm.scheduleDate = e;
                    }}})],1)])]),_c('div',{staticClass:"buttonsdiv"},[_c('div',{staticClass:"innerbuttons"},[_c('button',{staticClass:"btn-secondary",on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.getScreenText("BTN_CANCEL_CAPS"))+" ")]),_c('progress-button',{ref:"UserAddUpdateProgress",attrs:{"bx-attr":"save-btn","disableButton":(_vm.role == 'program admin' &&
                    _vm.selectedPrograms.length &&
                    _vm.scheduleDate) ||
                  ((_vm.role == 'session admin' || _vm.role == 'staff') &&
                    _vm.scheduleDate &&
                    _vm.selectedSites.length &&
                    _vm.selectedProgram != '') ||
                  (_vm.role == 'no access' &&
                    _vm.selectedSites.length &&
                    _vm.selectedProgram != ''),"buttonIndex":_vm.getScreenText('BTN_SAVE'),"buttonText":_vm.getScreenText('BTN_SAVE'),"updateProgress":true},on:{"submitData":function($event){return _vm.savePrivileges()}}})],1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mobile-close-icon uk-modal-close",attrs:{"bx-attr":"submissions-navigation-close-mobile-popup"}},[_c('img',{staticClass:"mobile-close-icon-image",attrs:{"src":require("@/assets/images/icon-blue/closeIconBlue.svg"),"alt":"close-icon"}})])}]

export { render, staticRenderFns }