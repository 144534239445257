












































































import { SendInvitationUser } from "@/popupcomponents/sendInvitationUser/SendInvitationUser";
export default SendInvitationUser;
