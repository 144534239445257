import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import manageListStore from "@/store/modules/manageList";
import ActionsDropdown from '@/commoncomponents/actionsdropdowncomponent/ActionsDropdown.vue';
import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from '@/utilities/commonFunctions';
import LocalStorage from "@/utilities/localStorageUtil";
import SmartTableContainer from '@/commoncomponents/smartTableComponents/SmartTableContainer.vue';
import UIkit from "uikit";
import programList from '@/store/modules/programList';
import DeleteUsers from '@/popupcomponents/deletePopUp/deletePopUp.vue';
import addUpdateUser from '@/canvas-components/createupdatepartneruser/addUpdateUser/addUpdateUser.vue';
import table from '@/store/modules/table';
import { bulkResetPassword, deleteUsers, DownloadUserCSV, sendBulkInvitation, sendBulkRevoke } from '@/services/userService/users-api';
import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import AddPrivilegesPopup from './addPrivilegesPopUp/AddPrivilegesPopUp.vue';
import SendInvitationUser from '@/popupcomponents/sendInvitationUser/SendInvitationUser.vue';
import { IDownloadUsersPayload, IUsersPayload } from '@/Model/model';
import ActionIgnoredPopup from '@/popupcomponents/actionIgnoredPopup/ActionIgnoredPopup.vue';
import ResetPasswordPopup from '@/commoncomponents/ResetPasswordPopup/ResetPasswordPopup.vue';
import ActionButton from '@/commoncomponents/ActionButton.vue';
import ActionButtonGroup, { ActionButtonGroupProps } from '@/commoncomponents/ActionButtonGroup.vue';
import ActionMenuButton from '@/commoncomponents/ActionMenuButton.vue';

@Component({
    components: {
        'actions': ActionsDropdown,
        'smart-table': SmartTableContainer,
        'add-user': addUpdateUser,
        'delete-users': DeleteUsers,
        "bouncing-preloader": BouncingPreloaderComponent,
        'add-privileges-pop-up': AddPrivilegesPopup,
        'bulk-invite-and-revoke-popup': SendInvitationUser,
        'action-ignored-popup': ActionIgnoredPopup,
        'reset-password-popup': ResetPasswordPopup,
        'action-button': ActionButton,
        'action-button-group': ActionButtonGroup,
        ActionMenuButton
    }
})
export default class Users extends Vue {

    public tableName: string = 'user-table';
    private objScreenText: ScreenText = new ScreenText();
    public actionsAccountDropdown: any = JSON.parse(JSON.stringify(APP_CONST.ACTIONS_ACCOUNT_DROP));
    public dropdownList: any = [];
    public currentRoleId : number = 0;
    public users_header: any = APP_CONST.USERS_HEADER;
    public total_items: number = 0;
    public totalActiveCounts : number = 0;
    public initialPage: number | null = 0;
    public isSearching:boolean= false;
    public displayColumn:Array<Object>=[]
    public users_list:any=[]
    public page: number= 1;
    public key: Array<string> = ["firstName", "lastName", "invitationStatus","invitationDate","programs","lastlogin","site/sessions","position","email","phoneNumber","revoke"];
    public search: string = '';
    public searchCount: number = APP_CONST.DEFAULT_TABLE_SEARCH_ROWS;
    public hoverText:string='';
    public hoverStyleObj: any= {};
    public hoverId: number = 0;
    private accountPayload : any = APP_CONST.MANAGE_PAYLOAD;
    public manage_list: any = '';
    public totalItems: number = 0;
    public globalSearch:string=''
    public searchField:string=""
    public accountId:number=Number(APP_UTILITIES.getCookie('accountId'));
    public settings:any=''
    public isResizer:boolean = true;
    public isSortingSearching:boolean = true;
    public responseStatus:number=0;
    sortDir: any = 0;
    sortField:string="firstName";
    public siteSessionArray:any=''
    public programNameArray:any=''
    public systemRoleArray:any=[]
    public isSearch:boolean=false;
    public positionArray:any='';
    public pageStatus :boolean=false;
    public allUsersPageStatus: boolean = false;
    public usersResponseData:object={}
    public localSearch:boolean=false;
    public isSearchRes:boolean= true;
    public globalSearchEmptyState:boolean=false;
    public globalSearchApiStatus:number=0;
    public searchLoader: boolean=false;
    public systemRoleSerach : any = {systemRole :  '' , invitationStatus : ''}
    public searchLists: any = APP_CONST.USERSTAB_HEADERDROPDOWN_SEARCHLIST;
    public usersObj = {
                    completeResponse:{},
                    firstName: "",
                    lastName: '',
                    invitationStatus: '',
                    invitationDate: '',
                    programName: "",
                    lastLogin: '',
                    siteName: '',
                    position: '',
                    email:'',
                    phone:'',
                    revokeDate:'',
                    systemRole:'',
                    allUniqueRolesArray:'',
                    userId:'',
                    selected:false
                }
    public allUniqueRolesArray:string=''
    public usersObjArray:any=[]
    public disableDownloadButton:boolean=false;
    public noDataApiResponseCode:number=APP_CONST.RESPONSE_204;
    public currentPayload: IUsersPayload = APP_CONST.MANAGE_USERS_PAYLOAD;
    public bulkPrivilegeRole:string=''
    public bulkPrivilegeSearch:{search:string, searchField:string}={search:'', searchField:''}
    public viewUserDetailsData:object= JSON.parse(JSON.stringify(APP_CONST.VIEW_USER_DATA));
    public programRoleData:{role:string,positions:string[],userRoleId:string,programs:{id:number,name:string,userRoleId:string}[]}={
        role: '',
        positions:[],
        programs: [],
        userRoleId:''
    }

    public otherRolesViewData:[]=[]
    public accountAdminRoleViewId:number = 0;
    public primaryaccountAdminRoleViewId:number = 0;
    public popupFunction:string=''
    public alertPopUpInstructions: {} = {first:'The action was NOT completed for the below', second: 'users as they are ineligible for the action.'}
    public failedActionUserNames: any[] = []
    public displayAll: boolean = true
    public loggedInUserId:any = '';
    public statusChangeTimer: number = APP_CONST.ZERO;
    public showNewToolbar: boolean = false; //TODO: remove this when removing feature flag user-management-team-actionbar

    @Prop()
   payload!: any
    public userssId: Array<number>= [];
    public deleteUsersIndex : Array<number> = [];
    public usersIdLength:number = 0;
    public allUsers: any = [];
    public initialTotalItems: number = APP_CONST.ZERO;
    public localConst:any = APP_CONST;
    public isClearSearch:boolean = APP_CONST.FALSE;
    public isCountAvailable: boolean = false ;
    public countOrName: number|string = '' ;
    public toolbarOptions: ActionButtonGroupProps[] = [];
    public toolbarOptionsDefault: ActionButtonGroupProps[] = [{
        label: this.objScreenText.getScreenText('RESET_PASSWORD'),
        icon: 'unlock',
        value: 'Reset Password',
        tone: 'neutral',
        show: false,
    }, {
      label: this.objScreenText.getScreenText('ADD_PRIVILEGES'),
      icon: 'plus',
      value: 'Add Privileges',
      tone: 'neutral',
      show: false,
    }, {
      label: this.objScreenText.getScreenText('SEND_INVITATION_ACTION'),
      icon: 'envelope',
      value: 'Send Invitation',
      tone: 'neutral',
      show: false,
    }, {
      label: this.objScreenText.getScreenText('REVOKE_ACCESS_ACTION'),
      icon: 'ban',
      value: 'Revoke Access',
      tone: 'neutral',
      show: false,
    }, {
      onlyIcon: true,
      label: this.objScreenText.getScreenText('DELETE_EVENT'),
      icon: 'trash',
      value: 'Delete',
      tone: 'primary',
      show: false,
    }];
    public showEmergentMenu = false;

    private globalSearchCookie = 'teams_globalSearch';

    public getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }

    @Watch('globalSearch', { deep: true })
    watchGlobalSearch(globalSearch: string): void {
      APP_UTILITIES.setCookie(this.globalSearchCookie, globalSearch, 1);
    }

    get selectedItems(): any[] {
      return this.users_list.filter((item: any) => item.selected);
    }

    get usersPayload(){
        return manageListStore.usersTabPayload
    }


    get allDataSelected(){
        return table.allDataSelected;
    }

    get usersResizeArray() {
        return manageListStore.usersResizeArray;
    }

    get inviteScheduleDate(){
        return programList.scheduleDateInvitation;
    }

    get accountDetails(){
        return programList.accountInfo;
    }

    get addEditViewModeValue(){
        return programList.addEditViewModeValue
    }

    get searchUser(){
      return manageListStore.searchUser;
    }

    get isLoaderVisible(){
        return (this.initialTotalItems>=this.localConst.HUNDRED || this.initialTotalItems <= this.localConst.ZERO)
    }

    computeToolbarOptions(): void {
      const [ resetPassword, addPrivileges, sendInvitation, revokeAccess, trash ] = this.toolbarOptionsDefault;
      const [, resetPasswordDd, deleteUser, addUserPrivilegesDd, sendInvitationDd, revokeAccessDd ] = this.actionsAccountDropdown;
      resetPassword.show = !resetPasswordDd.disable;
      addPrivileges.show = !addUserPrivilegesDd.disable;
      sendInvitation.show = !sendInvitationDd.disable;
      revokeAccess.show = !revokeAccessDd.disable;
      trash.show = !deleteUser.disable;
      this.toolbarOptions = [ resetPassword, addPrivileges, sendInvitation, revokeAccess, trash ];
      this.toolbarOptions = this.toolbarOptions.filter((item: any) => item.show);
     }


    beforeMount() {
      const globalSearch = APP_UTILITIES.getCookie(this.globalSearchCookie);
      this.globalSearch = globalSearch === 'null' || !globalSearch
        ? ''
        : String(globalSearch);
      this.filterDropdownByRoleId();
      let storedAccountId: any = APP_UTILITIES.getCookie('accountId');
      const storedSearchCount = LocalStorage.getFromLocalStorage(`${this.tableName}_searchCount`);

      this.searchCount = storedSearchCount ? Number(storedSearchCount) : APP_CONST.DEFAULT_TABLE_SEARCH_ROWS;

      manageListStore.mutateUsersPayload(JSON.parse(JSON.stringify(APP_CONST.MANAGE_PAYLOAD)));
      manageListStore.mutateUsersPayload({
        ...manageListStore.usersPayload,
        id: parseInt(storedAccountId),
        search:"",
        count: this.searchCount
      });
    }

    filterDropdownByRoleId(){
        let userRoles: any = APP_UTILITIES.getCookie("highest_role");
        let userData = userRoles && JSON.parse(userRoles);
        this.currentRoleId = userData;
        if(this.currentRoleId <= 4){
            this.dropdownList = this.actionsAccountDropdown;
        }else if(this.currentRoleId == 5){
            this.actionsAccountDropdown.splice(1, 2)
            this.dropdownList = this.actionsAccountDropdown
            this.searchLists = APP_CONST.USERSTAB_HEADERDROPDOWN_SEARCHLIST_PROGRAMADMIN
        }else if(this.currentRoleId == 6){
            this.actionsAccountDropdown.splice(1,5)
            this.dropdownList = this.actionsAccountDropdown
            this.searchLists = APP_CONST.USERSTAB_HEADERDROPDOWN_SEARCHLIST_SESSIONADMIN
        }
    }

    async created() {
       this.showNewToolbar = await APP_UTILITIES.getFeatureFlag(APP_CONST.FEATURE_KEYS.userManagementTeamActionBar);
        programList.mutateUserFilteredObject(JSON.parse(JSON.stringify(APP_CONST.USERS_HEADER.MOVABLE)));
        manageListStore.mutateCurrentObj({ ...this.searchUser, firstName:'', lastName:'', systemRole: '', invitationStatus: '', invitationDate:'', programName:'', lastLogin:'', siteName:'', position:'', email:'', phone:'', revokeDate:'', isExactMatch: false });
    }


    revokeDateRemove(){
        let userRoles:number = JSON.parse(JSON.stringify(APP_UTILITIES.getCookie("highest_role")))
        if(userRoles==APP_CONST.SESSION_ADMIN_ROLE_ID){
            let revokeDateIndex = this.users_header.MOVABLE.findIndex((header: any) => header.value == APP_CONST.REVOKE_DATE)
            this.users_header.MOVABLE.splice(revokeDateIndex, 1)
           }
        }
    mounted(){
        this.revokeDateRemove()
        this.getData()
        this.loggedInUserId = APP_UTILITIES.getCookie("user_id");
     }

     getData(){
        let authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : "";
        this.search= this.usersPayload && this.usersPayload.search ? this.usersPayload.search : this.search;
        this.searchField = this.usersPayload && this.usersPayload.searchField ? this.usersPayload.searchField : this.searchField;
        this.sortDir = this.usersPayload && this.usersPayload.sortDir ? this.usersPayload.sortDir : this.sortDir;
        this.sortField = this.usersPayload && this.usersPayload.sortField ? this.usersPayload.sortField : this.sortField;
        this.manageList();
        let accountId:any ;
        let userRoles: any = APP_UTILITIES.getCookie("highest_role");
        if(userRoles>=4){
            var role: any = APP_UTILITIES.getCookie("user_role");
            var userData = JSON.parse(role);
            accountId =userData[0].accountId;
        }else{
             accountId = APP_UTILITIES.getCookie('accountId');
        }
        if (APP_UTILITIES.getFilteredColumns(`${authKey}-UserSettingsData`)) {
            let userSettingsData:any=APP_UTILITIES.getFilteredColumns(`${authKey}-UserSettingsData`)
            userSettingsData = JSON.parse(userSettingsData)
            if(userSettingsData.hasOwnProperty([accountId])){
                Object.keys(userSettingsData).forEach(key => {
                    if(key==accountId){
                      programList.mutateUserFilteredObject(userSettingsData[key])
                    }
                  });
              }
        }
        this.displayColumn = programList.userFilteredObject;
        let userRole:number = JSON.parse(JSON.stringify(APP_UTILITIES.getCookie("highest_role")))
        if(userRole==APP_CONST.SESSION_ADMIN_ROLE_ID){
            let revokeDateIndexGear = this.displayColumn.findIndex((header: any) => header.value == APP_CONST.REVOKE_DATE)
            this.displayColumn.splice(revokeDateIndexGear, 1)
           }
        this.isClearSearch = false;
     }

    selectionChecker(){
        this.allUsers.forEach((user: any) => {
            user.selected = false;
        });
        this.allUsers && this.allUsers.forEach((user: any) => {
            table.allDataSelected && table.allDataSelected.forEach((elementChecked: any) => {
                if (elementChecked.userId === user.userId && user.firstName === elementChecked.firstName && user.lastName === elementChecked.lastName) {
                    user.selected = elementChecked.selected;
                }
            });
        })
        if (table.allSelect) {
            table.mutateSelectAll(true);
            this.allUsers && this.allUsers.forEach((user: any) => {
                user.selected = true;
            })
            this.allUsers && this.allUsers.forEach((user: any) => {
                table.unSelectData && table.unSelectData.length && table.unSelectData.forEach((elementChecked: any) => {
                    if (elementChecked.userId === user.userId && user.firstName === elementChecked.firstName) {
                        user.selected = false;
                    }
                });
            })
        }
        this.allUsers && this.allUsers.forEach((user: any) => {
            this.users_list && this.users_list.forEach((elementChecked: any) => {
                if (elementChecked.userId === user.userId && user.firstName === elementChecked.firstName && user.lastName === elementChecked.lastName) {
                    user.selected = elementChecked.selected;
                }
            });
        })
        this.openActionDropdown();
    }

     async manageList() {
        let accountId ;
        let userRoles: any = APP_UTILITIES.getCookie("highest_role");
        if(userRoles>=4){

            var role: any = APP_UTILITIES.getCookie("user_role");
        var userData = JSON.parse(role);
        accountId =userData[0].accountId;
        }else{
             accountId = APP_UTILITIES.getCookie('accountId');
        }
        this.isSearchRes = false;
        const payload = this.currentRoleId== APP_CONST.FOUR ? APP_CONST.USERS_PAYLOAD  :  APP_CONST.MANAGE_USERS_PAYLOAD;
        if (!this.globalSearch.trim().length|| this.globalSearch.trim().length>=3) {
        payload.Search = this.search
        payload.Id = Number(accountId)
        payload.RoleType = this.currentRoleId
        payload.Page =  this.globalSearch ? 1 : this.page
        payload.Count = this.searchCount;
        payload.SortField = this.sortField?this.sortField: 'firstName'
        payload.SortDir = this.sortDir?this.sortDir:1
        payload.GlobalSearch = this.globalSearch
        payload.SearchField = this.searchField;
        payload.isExactMatch = this.usersPayload && this.usersPayload.isExactMatch ? this.usersPayload.isExactMatch: false;
        this.currentPayload = payload;
        if(this.searchField.length){
             this.localSearch = true;
            }else if(!this.searchField.length && this.responseStatus != 204 && this.globalSearch){
                this.localSearch = false;
            }
        let data:any;
        let siteData = [];
        let programData = [];
        this.responseStatus = 0
        await manageListStore.getAllUsersList(payload).then((usersData)=>{
            this.pageStatus = true;
            if(this.globalSearch.trim().length>=3){
                this.globalSearchApiStatus = usersData.status;
            }
            if(usersData.status==200){
                this.usersResponseData=usersData
                this.responseStatus = usersData.status
                this.usersObjArray = [];
                for(let i=0;i<usersData.data.results.length;i++){
                    this.usersObj.completeResponse = usersData.data.results[i]
                    this.usersObj.firstName=usersData.data.results[i].firstName
                    this.usersObj.lastName = usersData.data.results[i].lastName

                    this.usersObj.invitationDate= usersData.data.results[i] && usersData.data.results[i].systemRoleList && usersData.data.results[i].systemRoleList[0].invitationDate
                    siteData = this.getSiteSessions(usersData)
                     let lengthOfSiteData = siteData[i].length-1
                     if(payload.Search && this.searchField == 'siteName'){
                         for(let m = 0;m < siteData[i].length; m++){
                             let searchSite=decodeURI(payload.Search)
                             if(siteData[i][m].toLowerCase().includes(searchSite.toLowerCase())){
                                 if(siteData[i].length > 1){
                                         this.usersObj.siteName = siteData[i][m] + " +"+ lengthOfSiteData;
                                 }
                                 else if(siteData[i].length == 1){
                                     this.usersObj.siteName = siteData[i][m];
                                 }
                                 break;
                             }
                         }
                     }
                     else{
                    if(siteData[i].length==0){
                        this.usersObj.siteName = '--'
                    }else if(siteData[i].length > 1 && this.sortField && this.sortField=='siteName'){
                        if(payload.SortDir==1){
                            this.usersObj.siteName = siteData[i][0]+ " +"+ lengthOfSiteData
                        }else if(payload.SortDir==2){
                            this.usersObj.siteName = siteData[i][lengthOfSiteData]+ " +"+ lengthOfSiteData
                        }
                    }else if(siteData[i].length > 1){
                        this.usersObj.siteName = siteData[i][0]+ " +"+ lengthOfSiteData
                    }else if(siteData[i].length == 1){
                        this.usersObj.siteName = siteData[i][0]
                    }
                }
                    programData = this.getProgramName(usersData);
                    let lengthOfProgData = programData[i].length-1;
                    if(payload.Search && this.searchField == 'programName'){
                        let searchProgram=decodeURI(payload.Search)
                        for(let m = 0; m < programData[i].length; m++){
                            if(programData[i][m].toLowerCase().includes(searchProgram.toLowerCase())){
                                if(programData[i].length > 1){
                                        this.usersObj.programName = programData[i][m] + " +"+ lengthOfProgData;
                                }
                                else if(programData[i].length == 1){
                                    this.usersObj.programName = programData[i][m];
                                }
                                if(this.sortField=='programName' && this.sortDir==1){
                                    break;
                                }
                            }
                        }
                    }else{
                        if(programData[i].length == 0){
                            this.usersObj.programName = '--';
                        }else if(programData[i].length > 1 && this.sortField && this.sortField=='programName'){
                            if(payload.SortDir==1){
                                this.usersObj.programName = programData[i][0] + " +"+ lengthOfProgData;
                            }else if(payload.SortDir==2){
                                this.usersObj.programName = programData[i][lengthOfProgData] + " +"+ lengthOfProgData;
                            }
                        }else if(programData[i].length > 1){
                            this.usersObj.programName = programData[i][0] + " +"+ lengthOfProgData;
                        }else if(programData[i].length == 1){
                            this.usersObj.programName = programData[i][0];
                        }
                    }
                    let systemRole = this.getSystemRole(usersData);
                    let lengthOfSystemRoleData = systemRole[i].length-1;
                    if(payload.Search && this.searchField == 'systemRole'){
                        for(let m = 0;m < systemRole[i].length; m++){
                            let searchRole=decodeURI(payload.Search)
                            if(systemRole[i][m].toLowerCase().includes(searchRole.toLowerCase())){
                                if(systemRole[i].length > 1){
                                        this.usersObj.systemRole = systemRole[i][m] + " +"+ lengthOfSystemRoleData;
                                    }
                                else if(systemRole[i].length == 1){
                                    this.usersObj.systemRole = systemRole[i][m];
                                }
                                break
                            }
                        }
                    }else{
                        if(systemRole[i].length == 0){
                            this.usersObj.systemRole = '--';
                        }else if(systemRole[i].length > 1 && this.sortField && this.sortField=='systemRole'){
                            if(payload.SortDir == 1){
                                this.usersObj.systemRole = systemRole[i][0] + " +"+ lengthOfSystemRoleData;
                            }else if(payload.SortDir == 2){
                                this.usersObj.systemRole = systemRole[i][lengthOfSystemRoleData] + " +"+ lengthOfSystemRoleData;
                            }

                        }else if(systemRole[i].length > 1){
                            this.usersObj.systemRole = systemRole[i][0] + " +"+ lengthOfSystemRoleData;
                        }else if(systemRole[i].length == 1){
                            this.usersObj.systemRole = systemRole[i][0];
                        }
                    }
                    this.usersObj.allUniqueRolesArray=this.allUniqueRolesArray[i]
                    let positionData = this.getPostion(usersData);

                    let lengthOfPositionData = positionData[i].length-1;
                    if(payload.Search && this.searchField == 'position'){
                        for(let m = 0;m < positionData[i].length; m++){
                            let searchPosition=decodeURI(payload.Search)
                            if(positionData[i][m].toLowerCase().includes(searchPosition.toLowerCase())){
                                if(positionData[i].length > 1){
                                        this.usersObj.position = positionData[i][m] + " +"+ lengthOfPositionData;
                                    }
                                else if(positionData[i].length == 1){
                                    this.usersObj.position = positionData[i][m];
                                }
                                break
                            }
                        }
                    }else{
                        if(positionData[i].length == 0){
                            this.usersObj.position = '--';
                        }else if(positionData[i].length > 1){
                            if(payload.SortDir==1){
                                if(positionData[i][0] != APP_CONST.BLANK && positionData[i].length > APP_CONST.ONE){
                                    this.usersObj.position = positionData[i][0] + " +"+ lengthOfPositionData;
                                }else if(positionData[i][0] == APP_CONST.BLANK && positionData[i].length == APP_CONST.TWO){
                                    this.usersObj.position = positionData[i][1];
                                }else if(positionData[i][0] == APP_CONST.BLANK && positionData[i].length > APP_CONST.ONE){
                                    this.usersObj.position = positionData[i][1] + " +"+ (lengthOfPositionData-APP_CONST.ONE);
                                }else{
                                    this.usersObj.position = positionData[i][1];
                                }
                            }else if(payload.SortDir==2){
                                this.usersObj.position = positionData[i][lengthOfPositionData] + " +"+ lengthOfPositionData;
                            }
                        }else if(positionData[i].length == 1){
                            this.usersObj.position = positionData[i][0];
                        }
                    }
                    this.usersObj.invitationStatus=usersData.data.results[i].systemRoleList && usersData.data.results[i].systemRoleList[0].invitationStatus;
                    this.usersObj.lastLogin = usersData.data.results[i].lastLogin? usersData.data.results[i].lastLogin :usersData.data.results[i].lastLogin;
                    this.usersObj.userId = usersData.data.results[i].userId;
                    this.usersObj.email = usersData.data.results[i].email;

                    let userPhoneNumber:any = APP_CONST.BLANK_PHONE_NUMBER;
                    let phoneType: string = '';
                    if (usersData.data.results[i].phoneNumber) {
                        userPhoneNumber = APP_UTILITIES.formatNumber(usersData.data.results[i].phoneNumber)
                    }
                    if (usersData.data.results[i].phoneType) {
                        phoneType = usersData.data.results[i].phoneType;
                    }
                    this.usersObj.phone= userPhoneNumber + ' ' + phoneType

                    this.usersObj.revokeDate = usersData.data.results[i].revokeDate;
                    this.usersObj.userId = usersData.data.results[i].userId
                    this.usersObj.selected = false
                    this.usersObjArray.push({...this.usersObj})
                }
                this.users_list=this.usersObjArray
                this.selectAllCheck();
                this.total_items = usersData.data.count;
                this.totalActiveCounts = usersData.data.totalActiveCount;
                const listOnSearch = payload.Search ? APP_CONST.NO_MANAGE_USER_FOUND : APP_CONST.NO_USER_LIST_FOUND;
                this.isSearching = false;
            }else if(usersData.status==204){
                this.users_list = [];
                this.total_items = 0;
                this.totalActiveCounts = 0;
                this.responseStatus = usersData.status;
                if(this.globalSearch.length && this.globalSearch.length>=3){
                    this.globalSearchEmptyState = true;
                }
            }
            else{
                this.users_list = [];
                this.total_items = 0;
                this.totalActiveCounts = 0;
                this.isSearching = payload.Search ? true : false;
            }
        })
        let allUsersPayload = JSON.parse(JSON.stringify(payload));
        allUsersPayload.Page = "";
        allUsersPayload.Count = "";
        allUsersPayload.Search = decodeURI(allUsersPayload.Search);
        await manageListStore.getAllUsersList(allUsersPayload).then((usersData: any)=>{
            this.allUsersPageStatus = true;
            usersData && usersData.data && usersData.data.results && usersData.data.results.forEach((elem: any) => {
                elem.selected = false;
            })
            this.allUsers = usersData.data.results || [];
            this.selectionChecker();
        })
        }
        if(this.initialTotalItems == APP_CONST.ZERO){
            this.initialTotalItems = this.total_items;
          }
          this.computeToolbarOptions();
    }

    selectAllCheck(){
        this.users_list && this.users_list.forEach((user: any) => {
            table.allDataSelected && table.allDataSelected.forEach((elementChecked: any) => {
                if (elementChecked.userId === user.userId && user.firstName === elementChecked.firstName) {
                    user.selected = elementChecked.selected;
                }
            });
        })
        if (table.allSelect) {
            table.mutateSelectAll(true);

            this.users_list && this.users_list.forEach((user: any) => {
                user.selected = true;
            })
            this.users_list && this.users_list.forEach((user: any) => {
                table.unSelectData && table.unSelectData.length && table.unSelectData.forEach((elementChecked: any) => {
                    if (elementChecked.userId === user.userId && user.firstName === elementChecked.firstName) {
                        user.selected = false;
                    }
                });
                if (this.loggedInUserId && parseInt(this.loggedInUserId) === user.userId) {
                    user.selected = false;
                }
            })
        }
    }

    clearSelection() {
        table.mutateSelectAll(false);
        table.mutateFewCheckData('');
        table.mutateAllSelectedData([]);
        table.mutateUnSelectedData([]);
        let element = <HTMLInputElement>document.getElementById('all-data-checkbox');
        if (element) {
            element.checked = false;
        }
        this.users_list && this.users_list.forEach((element: any) => {
            element.selected = false;
        });
        this.userssId = []
    }

    setPage(page: number){
        manageListStore.mutateUsersPayload({...this.usersPayload, sortField: this.sortField, sortDir: this.sortDir})
        this.page=page;
        this.manageList()
    }

    callHover(value:string, event: any, id?: number){
        this.hoverText = value;
        /* istanbul ignore else */
        if (id) {
            this.hoverId = id;
        }
        const boundBox = event && event.target && event.target.getBoundingClientRect();
        const coordX = boundBox.left;
        const coordY = boundBox.top;
        this.hoverStyleObj = {
            position: 'fixed',
            top:(coordY + 45).toString() + "px",
            left:(coordX + 15).toString() + "px"
        }
    }

    callHoverOut(){
        this.hoverText = ''
        this.hoverId = 0;
    }

    clearSearch() {
        this.isClearSearch = APP_CONST.TRUE;
        this.searchLoader = false;
        this.pageStatus = this.isLoaderVisible?false:this.pageStatus;
        this.allUsersPageStatus = this.isLoaderVisible?false:this.allUsersPageStatus;
        this.globalSearch = "";
        this.isSearching = false;
        this.clearSelection();
        this.manageList();
    }

    openAddPanel(){
        programList.mutateAddEditViewMode('add')
        if(UIkit.offcanvas('#add-update-user')){
            UIkit.offcanvas('#add-update-user').show();
        }
    }


    viewUserDetails(data:any){
        programList.mutateAddEditViewMode('view')
        this.viewUserDetailsData=data


        let dataToView:any=[]
        data.completeResponse.systemRoleList.forEach((roleListData:any)=>{
            if(roleListData.name == APP_CONST.PROGRAAM_ADMIN){
                this.programRoleData.role=roleListData.name
                    this.programRoleData.positions
                if(roleListData.position && !this.programRoleData.positions.includes(roleListData.position)){
                    this.programRoleData.positions.push(roleListData.position)
                }
                if(roleListData.programName){
                    this.programRoleData.programs.push({id:roleListData.programId ,name:roleListData.programName,userRoleId:roleListData.id})
                }else{
                    this.programRoleData.userRoleId =  roleListData.id;
                }
            }

            if(roleListData.name == APP_CONST.ACCOUNT_ADMIN){
                this.accountAdminRoleViewId = roleListData.id;
            }else if(roleListData.name == APP_CONST.SYSTEM_ROLE_LIST[0].name){
                this.primaryaccountAdminRoleViewId = roleListData.id;
            }

            if(roleListData.name != APP_CONST.ACCOUNT_ADMIN && roleListData.name != APP_CONST.SYSTEM_ROLE_LIST[0].name && roleListData.name != APP_CONST.PROGRAAM_ADMIN){
                if(!dataToView.length){
                    let newObj:{role:'',userRoleId:string, programData:{programName:{id:number,name:string}, sites:{id:number,name:string}[], positions:string[]}[]} = {
                        role: '',
                        userRoleId:'',
                        programData: []
                    }
                    let otherObj:{programName:{id:number,name:string}, sites:{id:number,name:string,userRoleId:number}[], positions:string[]} = {
                        programName:{id:APP_CONST.ZERO,name:APP_CONST.BLANK},
                        sites:[],
                        positions: []
                    }
                    newObj.role=roleListData.name
                    if(!roleListData.programId){
                        newObj.userRoleId = roleListData.id
                    }
                    otherObj.programName.id = roleListData.programId;
                    otherObj.programName.name = roleListData.programName;
                    otherObj.sites.push({id:roleListData.siteId, name:roleListData.siteName,userRoleId:roleListData.id})
                    if(roleListData.position && !otherObj.positions.includes(roleListData.position)){
                        otherObj.positions.push(roleListData.position)
                    }
                    newObj.programData.push(otherObj)
                    dataToView.push(newObj)
                }
                else{
                    let outerIndex=0
                    dataToView.forEach((viewData:any,index:number)=>{
                        if(viewData.role==roleListData.name){
                            outerIndex+=1
                            let innerIndex=0
                            viewData.programData.forEach((progData:any)=>{
                                if(progData.programName.id==roleListData.programId){
                                    innerIndex+=1
                                    progData.sites.push({id:roleListData.siteId, name:roleListData.siteName,userRoleId:roleListData.id})
                                    if(roleListData.position && !progData.positions.includes(roleListData.position)){
                                        progData.positions.push(roleListData.position)
                                    }
                                }


                            })
                            if(innerIndex==0){
                                    let otherObj:{programName:{id:number,name:string}, sites:{id:number,name:string,userRoleId:number}[], positions:string[]} = {programName:{id:APP_CONST.ZERO,name:APP_CONST.BLANK}, sites:[], positions:[]}
                                    otherObj.programName.id = roleListData.programId;
                                    otherObj.programName.name = roleListData.programName;
                                    otherObj.sites.push({id:roleListData.siteId, name:roleListData.siteName,userRoleId:roleListData.id});
                                    if(roleListData.position && !otherObj.positions.includes(roleListData.position)){
                                        otherObj.positions.push(roleListData.position)
                                    }
                                    viewData.programData.push(otherObj)
                            }
                        }

                    })
                    if(outerIndex==0){
                            let newObj:{role:'',userRoleId:string, programData:{programName:{id:number,name:string}, sites:{id:number,name:string}[], positions:string[]}[]} = {
                                role: '',
                                userRoleId:'',
                                programData: []
                            }
                            let otherObj:{programName:{id:number,name:string}, sites:{id:number,name:string,userRoleId:number}[], positions:string[]} = {
                                programName:{id:APP_CONST.ZERO,name:APP_CONST.BLANK},
                                sites: [],
                                positions: []
                            }
                            newObj.role=roleListData.name
                            if(!roleListData.programId){
                                newObj.userRoleId = roleListData.id
                            }
                            otherObj.programName.id = roleListData.programId;
                            otherObj.programName.name = roleListData.programName;
                            otherObj.sites.push({id:roleListData.siteId, name:roleListData.siteName,userRoleId:roleListData.id});
                            if(roleListData.position && !otherObj.positions.includes(roleListData.position)){
                                otherObj.positions.push(roleListData.position)
                            }
                            newObj.programData.push(otherObj)
                            dataToView.push(newObj)
                    }
                }
            }
        })
        this.otherRolesViewData=dataToView
        if(UIkit.offcanvas('#add-update-user')){
            UIkit.offcanvas('#add-update-user').show();
        }
    }

    close(){
        let storedAccountId: any = APP_UTILITIES.getCookie('accountId');
        let payload = {accountId:storedAccountId}
        programList.accountById(payload);
        this.clearSelection();
        this.manageList();
        UIkit.offcanvas('#add-update-user').hide();
        if(this.programRoleData){
            this.programRoleData={
                role: '',
                positions:[],
                programs: [],
                userRoleId:''
            }
        }
        if(this.otherRolesViewData){
            this.otherRolesViewData=[]
        }
        this.accountAdminRoleViewId = APP_CONST.ZERO;
        this.primaryaccountAdminRoleViewId =APP_CONST.ZERO;
        this.allUniqueRolesArray ='';
        this.viewUserDetailsData = [];
    }

    deleteUsers(){
        const tempPayload = JSON.parse(JSON.stringify(this.currentPayload));
        let payload = {...tempPayload, isReport: false, selectedIds: [], notSelectedIds: [], isAllSelected: table.selectAll, Search: decodeURIComponent(this.currentPayload.Search)};
        delete payload.Count;
        delete payload.Page;
        let unSelectedIds: any = [];
        if (table.selectAll) {
            if (table.unSelectData.length) {
                unSelectedIds = table.unSelectData.map((user: any) => {
                    return user.userId;
                });
            }
        }
        payload.notSelectedIds = unSelectedIds;
        let userIds: any = this.allDataSelected.map((user: any) => {
            return user.userId;
        });
        payload.selectedIds = userIds;
        this.pageStatus = this.isLoaderVisible?false:this.pageStatus;
        this.allUsersPageStatus = this.isLoaderVisible?false:this.allUsersPageStatus;
        deleteUsers(payload)
          .then((res) => {
            /* istanbul ignore else */
            if (res.status === APP_CONST.RESPONSE_200) {
                this.userssId = [];
                this.removeChecked();
                this.clearSelection();
                this.resetPage();
                this.getData();
              if (UIkit.modal("#delete-popup-confirmation")) {
                UIkit.modal("#delete-popup-confirmation").hide();
              }
            }
          })
          .catch((error: any) => {
            console.log(error)
        });

    }

    revokeAccess(){
        const tempPayload = JSON.parse(JSON.stringify(this.currentPayload));
        let payload = {...tempPayload, isReport: false, selectedIds: [], notSelectedIds: [], isAllSelected: table.selectAll, revokeDate: '', Search: decodeURIComponent(this.currentPayload.Search)};
        delete payload.Count;
        delete payload.Page;
        let unSelectedIds: any = [];
        if (table.selectAll) {
            if (table.unSelectData.length) {
                unSelectedIds = table.unSelectData.map((user: any) => {
                    return user.userId;
                });
            }
        }
        payload.notSelectedIds = unSelectedIds;
        let userIds: any = this.allDataSelected.map((user: any) => {
            return user.userId;
        });
        payload.selectedIds = userIds;

        payload.revokeDate = this.dateFormat(this.inviteScheduleDate);
        this.failedActionUserNames = [];
        this.pageStatus = this.isLoaderVisible?false:this.pageStatus;
        this.allUsersPageStatus = this.isLoaderVisible?false:this.allUsersPageStatus;
        sendBulkRevoke(payload)
             .then((res) => {
                /* istanbul ignore else */
                if (res.status === APP_CONST.RESPONSE_200) {
                    res && res.data && res.data.data && res.data.data.fail && res.data.data.fail.forEach((failUsers: any) => {
                        let fullName = failUsers.firstName + " " + failUsers.lastName;
                        this.failedActionUserNames.push(fullName);
                    });
                    this.userssId = [];
                    programList.mutateScheduleDate(APP_CONST.BLANK);
                    this.removeChecked();
                    this.clearSelection();
                    this.resetPage();
                    if (UIkit.modal("#send-invitation-popup")) {
                        UIkit.modal("#send-invitation-popup").hide();
                    }

                    /* ignore istanbul else */
                    if(this.failedActionUserNames && this.failedActionUserNames.length && UIkit.modal('#actions-ignored-popup')){
                        UIkit.modal('#actions-ignored-popup').show()
                        let elem : any = document.getElementById('popup-content') ;
                        elem.scrollTo(0, 0);
                        const body = <HTMLBodyElement>document.querySelector("body");
                        body.classList.add("body-scroll-lock");
                    }
                           this.getData();
                }
            })
            .catch((error: any) => {
             console.log(error)
            });
    }

    sendInvitation(date:string){
        const tempPayload = JSON.parse(JSON.stringify(this.currentPayload));
        let payload = {...tempPayload, isReport: false, selectedIds: [], notSelectedIds: [], isAllSelected: table.selectAll, scheduleDate: '', Search: decodeURIComponent(this.currentPayload.Search)};
        delete payload.Count;
        delete payload.Page;
        let unSelectedIds: any = [];
        if (table.selectAll) {
            if (table.unSelectData.length) {
                unSelectedIds = table.unSelectData.map((user: any) => {
                    return user.userId;
                });
            }
        }
        payload.notSelectedIds = unSelectedIds;
        let userIds: any = this.allDataSelected.map((user: any) => {
            return user.userId;
        });
        payload.selectedIds = userIds;

        payload.scheduleDate = this.dateFormat(this.inviteScheduleDate);
        this.failedActionUserNames = [];
        this.pageStatus = this.isLoaderVisible?false:this.pageStatus;
        this.allUsersPageStatus = this.isLoaderVisible?false:this.allUsersPageStatus;
        sendBulkInvitation(payload)
            .then((res) => {
                /* istanbul ignore else */
                if (res.status === APP_CONST.RESPONSE_200) {
                    res && res.data && res.data.data && res.data.data.fail && res.data.data.fail.forEach((failUsers: any) => {
                        let fullName = failUsers.firstName + " " + failUsers.lastName;
                        this.failedActionUserNames.push(fullName);
                    });
                    this.userssId = [];
                    programList.mutateScheduleDate(APP_CONST.BLANK);
                    if(this.failedActionUserNames.length == APP_CONST.ZERO){
                        this.sendInvitationOrangeStripFunction();
                    }
                    this.removeChecked();
                    this.clearSelection();
                    this.resetPage();
                    if (UIkit.modal("#send-invitation-popup")) {
                        UIkit.modal("#send-invitation-popup").hide();
                    }

                    /* ignore istanbul else */
                    if(this.failedActionUserNames && this.failedActionUserNames.length && UIkit.modal('#actions-ignored-popup')){
                        UIkit.modal('#actions-ignored-popup').show()
                        let elem : any = document.getElementById('popup-content') ;
                        elem.scrollTo(0, 0);
                        const body = <HTMLBodyElement>document.querySelector("body");
                        body.classList.add("body-scroll-lock");
                    }
                    this.getData();
                }
            })
            .catch((error: any) => {
                console.log(error)
            });
    }

    resetPage(){
        this.page = APP_CONST.ONE;
        manageListStore.mutateUsersPayload({...this.usersPayload, page: APP_CONST.ONE})
    }

    sendInvitationOrangeStripFunction(){
        Vue.prototype.$eventHub.$emit(APP_CONST.SEND_INVITATION_STRIP_INDICATOR, {
            component: APP_CONST.SEND_INVITATION,
            stripShow: APP_CONST.TRUE,
            sendInvitationStripShow: APP_CONST.TRUE,
            stripText:
               APP_CONST.SEND_INVITATION_CONFIRMATION_TEXT,
            })
            setTimeout(() => {
            Vue.prototype.$eventHub.$emit(APP_CONST.SEND_INVITATION_STRIP_INDICATOR, {
                component: APP_CONST.BLANK,
                stripShow: APP_CONST.FALSE,
                sendInvitationStripShow: APP_CONST.FALSE,
                stripText: APP_CONST.BLANK,
            });
            }, APP_CONST.AUTO_SAVE_INTERVAL);
    }

    dateFormat(data: string) {
        const date = APP_UTILITIES.formatShortDate(data);
        return date;
    }

    removeChecked(){
        let element = <HTMLInputElement>document.getElementById('all-data-checkbox');
        if (element) {
         element.checked = false;
        }
        table.mutateFewCheckData('');
        table.mutateAllSelectedData([]);
    }

    openDeletePopup(){
        this.userssId = [];
        let unSelectedIds: any = [];
        let selectedIds:any = [];
        if (table.selectAll) {
            if (table.unSelectData.length) {
                unSelectedIds = table.unSelectData.map((user: any) => {
                    return user.userId;
                });
            }
            if (unSelectedIds && unSelectedIds.length) {
                this.usersIdLength = this.total_items - unSelectedIds.length;
            } else {
                this.usersIdLength = this.total_items;
            }
        }else{
            selectedIds = table.allDataSelected.map((user: any) => {
                return user.userId;
            });
            this.usersIdLength = selectedIds.length;
        }
        this.allUsers.forEach((user: any) => {
            if (user.selected) {
                this.userssId.push(user.userId);
            }
        });
        if(UIkit.modal('#delete-popup-confirmation') && this.userssId.length >0){
            UIkit.modal('#delete-popup-confirmation').show();
        }
    }

    openAddPrivilegesPopup(){
        this.userssId = [];
        let unSelectedIds: any = [];
        let selectedIds:any = [];
        if (table.selectAll) {
            if (table.unSelectData.length) {
                unSelectedIds = table.unSelectData.map((user: any) => {
                    return user.userId;
                });
            }
            if (unSelectedIds && unSelectedIds.length) {
                this.usersIdLength = this.total_items - unSelectedIds.length;
            } else {
                this.usersIdLength = this.total_items;
            }
        }else{
            selectedIds = table.allDataSelected.map((user: any) => {
                return user.userId;
            });
            this.usersIdLength = selectedIds.length;
        }
        this.allUsers.forEach((user: any) => {
            if (user.selected) {
                this.userssId.push(user.userId);
            }
        });
        if(UIkit.modal('#add-privileges-popup')){
            UIkit.modal('#add-privileges-popup').show();
        }
        let ele:any = document.getElementsByClassName('site-content-box')[0];
        setTimeout(()=>{ele && ele.focus()},100);
    }

    openBulkPopup(popupFunction:string){
        this.popupFunction=popupFunction
        this.userssId = [];
        let unSelectedIds: any = [];
        let selectedIds:any = [];
        if (table.selectAll) {
            if (table.unSelectData.length) {
                unSelectedIds = table.unSelectData.map((user: any) => {
                    return user.userId;
                });
            }
            if (unSelectedIds && unSelectedIds.length) {
                this.usersIdLength = this.total_items - unSelectedIds.length;
            } else {
                this.usersIdLength = this.total_items;
            }
        }else{
            selectedIds = table.allDataSelected.map((user: any) => {
                return user.userId;
            });
            this.usersIdLength = selectedIds.length;
        }
        this.allUsers.forEach((user: any) => {
            if (user.selected) {
                this.userssId.push(user.userId);
            }
        });
        if(UIkit.modal('#send-invitation-popup') && this.userssId.length > 0){
            UIkit.modal('#send-invitation-popup').show();
        }
    }

    performAction(key:string){
        if(key == "Add"){
            this.openAddPanel();
        }else if(key == "Delete"){
            this.openDeletePopup();
        }else if(key == "Upload CSV"){
            this.$router.push('/staffroster/new') ;
        }else if(key == "Add Privileges"){
            this.openAddPrivilegesPopup();
        }else if(key == "Send Invitation"){
            this.openBulkPopup('sendInvitation');
        }else if(key == "Revoke Access"){
            this.openBulkPopup('revokeAccess')
        }else if(key == "Reset Password"){
             this.openResetPasswordPopup();
        }
    }

    filterColumns(displayColumn:any){
        let isSelected = false;
           displayColumn && displayColumn.length && displayColumn.forEach((column: any) => {
            if(column.display) {
               isSelected = true;
            }
         });
         this.displayAll = isSelected;
         this.clearAllColumns(this.displayAll)
        programList.mutateUserFilteredObject(displayColumn);
        programList.mutateSettingsData(displayColumn);
        manageListStore.mutateUsersPayload({...this.usersPayload, sortField: this.sortField, sortDir: this.sortDir})
    }

    clearAllColumns(displayAll:boolean){
        let isSearchFieldFixed = this.users_header.FIXED.map((e:any)=> { return e.value; }).indexOf(this.searchField);

        let isSortFieldFixed = this.users_header.FIXED.map((e:any)=> { return e.value; }).indexOf(this.sortField);
        if(!displayAll && isSearchFieldFixed == APP_CONST.MINUS_ONE){
            this.search = this.searchField == APP_CONST.FIRST_NAME || this.searchField == APP_CONST.LAST_NAME ? this.search : ''
            this.searchField = this.searchField == APP_CONST.FIRST_NAME || this.searchField == APP_CONST.LAST_NAME ? this.searchField : '';
            manageListStore.mutateUsersSearchObj({ ...this.searchUser, systemRole: '', invitationStatus: '', invitationDate:'', programName:'', lastLogin:'', siteName:'', position:'', email:'', phone:'', revokeDate:'', isExactMatch: false })
            this.manageList()
    }
    if(!displayAll && isSortFieldFixed == APP_CONST.MINUS_ONE){
        this.sortDir = this.sortField == APP_CONST.FIRST_NAME || this.sortField == APP_CONST.LAST_NAME ? this.sortDir : APP_CONST.ZERO
        this.sortField = this.sortField == APP_CONST.FIRST_NAME || this.sortField == APP_CONST.LAST_NAME ? this.sortField : '';
        manageListStore.mutateSortPayload({ ...this.usersPayload , sortField: this.sortField, sortDir: this.sortDir});
        manageListStore.mutateUsersSearchObj({ ...this.searchUser, systemRole: '', invitationStatus: '', invitationDate:'', programName:'', lastLogin:'', siteName:'', position:'', email:'', phone:'', revokeDate:'', isExactMatch: false  })
        this.manageList()
    }
    }

    filterDisplayColumn(column:any){
        if(!column.display){
            if(this.usersPayload && (column.value == this.usersPayload.searchField)){
                this.search = ''
                this.searchField = ''
                let columnName = column.value;
                manageListStore.mutateUsersSearchObj({ ...this.searchUser, [columnName]: "" });
            this.manageList()
            }
            if(column.value == this.usersPayload.sortField){
                this.sortDir = 0
                this.sortField = '';
                manageListStore.mutateSortPayload({ ...this.usersPayload , sortField: this.sortField, sortDir: this.sortDir});
            this.manageList()
            }
        }
    }

    formatDate(date: string) {
        return APP_UTILITIES.formatShortDate(date);
      }

      sortData(sortObj: { sortField: string, sortDirection: number, customField: string }){
        this.pageStatus = this.isLoaderVisible?false:this.pageStatus;
        this.allUsersPageStatus = this.isLoaderVisible?false:this.allUsersPageStatus;
        this.sortField = sortObj.sortField ;
        this.sortDir = sortObj.sortDirection;
        this.page = 1;
        manageListStore.mutateSortPayload({ ...this.usersPayload , sortField: this.sortField, sortDir: this.sortDir});
        manageListStore.mutateUsersPayload({...this.usersPayload, sortField: this.sortField, sortDir: this.sortDir})
        this.globalSearch = this.globalSearch ? this.globalSearch: ''
        this.clearSelection();
        this.manageList();
      }

      searchData(search: {searchField: string, isExactMatch: boolean}){
        this.pageStatus = this.isLoaderVisible?false:this.pageStatus;
        this.allUsersPageStatus = this.isLoaderVisible?false:this.allUsersPageStatus;
        this.page = 1;
        this.searchField = search.searchField
        this.search = this.searchUser[search.searchField] ? this.searchUser[search.searchField] : '' ;
        this.globalSearch = this.globalSearch ? this.globalSearch:''
        if(search.searchField){
            manageListStore.mutateUsersSearchObj({ ...this.searchUser, [search.searchField]: (this.searchUser && this.searchUser[search.searchField] ? this.searchUser[search.searchField] : ""), isExactMatch: search.isExactMatch });
            manageListStore.mutateUsersPayload({...this.usersPayload, searchField: this.searchField, sortDir: this.sortDir, isExactMatch: search.searchField ? search.isExactMatch: false})
        }else{
            manageListStore.mutateUsersSearchObj({ ...this.searchUser, programName: "", programType: "", noOfScholar: "", enrolledScholars: "", programStatus: "", noOfSites: "", isExactMatch: false });
            manageListStore.mutateUsersPayload({...this.usersPayload, searchField: this.searchField, sortDir: this.sortDir, isExactMatch: search.searchField ? search.isExactMatch: false})
        }
        if(this.searchField.length){
            this.localSearch = true;
        }
        this.accountPayload.searchField = search.searchField;
        this.clearSelection();
        this.manageList();
      }

      getSiteSessions(data:any){
        let arrayA:any=[]
        let mainArray:any=[]
        let usersArray = data.data.results
            for(let i=0;i<usersArray.length;i++){
                if(usersArray[i].systemRoleList){
                    for(let j=0;j<usersArray[i].systemRoleList.length;j++){
                        let siteNameArray = usersArray[i].systemRoleList[j]
                        if(siteNameArray.hasOwnProperty(['siteName'])){
                            if(!arrayA.includes(usersArray[i].systemRoleList[j].siteName)){
                                arrayA.push(usersArray[i].systemRoleList[j].siteName)
                            }
                        }
                    }
                }
                if(arrayA.length){
                    mainArray.push(arrayA);
                }else{
                    mainArray.push([])
                }
                arrayA = [];
            }
            if((this.sortField && this.sortField=='siteName')||(this.searchField && this.searchField=='siteName')){
                mainArray.forEach((element1:any) => {
                    if(element1.length){
                        element1 = this.sortString(element1)
                    }
                });
            }
           this.siteSessionArray=mainArray
    return mainArray;
    }

    getSystemRole(data:any){
        let arrayA:any=[]
        let mainArray:any=[]
        let usersArray = data.data.results
            for(let i=0;i<usersArray.length;i++){
                if(usersArray[i].systemRoleList){
                    for(let j=0;j<usersArray[i].systemRoleList.length;j++){
                        let siteNameArray = usersArray[i].systemRoleList[j]
                        if(siteNameArray.hasOwnProperty(['name'])){
                            if(!arrayA.includes(usersArray[i].systemRoleList[j].name)){
                                arrayA.push(usersArray[i].systemRoleList[j].name)
                            }
                        }
                    }
                }
                if(arrayA.length){
                    mainArray.push(arrayA);
                }else{
                    mainArray.push([])
                }
                this.allUniqueRolesArray=mainArray
                arrayA = [];
            }
            if(this.sortField && this.sortField=='systemRole'){
                mainArray.forEach((element1:any) => {
                    if(element1.length){
                        element1 = this.sortString(element1)
                    }
                });
            }

           this.systemRoleArray=mainArray
    return mainArray;
    }

 sortString(strArray:any) {
    strArray = strArray.sort(function (a: any, b: any) {
        if (a.toLowerCase() < b.toLowerCase()) { return -1; }
        if (a.toLowerCase() > b.toLowerCase()) { return 1; }
        return 0;
      })
      return strArray
    }

    getProgramName(data:any){
        let arrayOfPrograms:any=[]
        let programArray:any=[]
        let usersArray = data.data.results
            for(let i=0;i<usersArray.length;i++){
                if(usersArray[i].systemRoleList){
                    for(let j=0;j<usersArray[i].systemRoleList.length;j++){
                        let siteNameArray = usersArray[i].systemRoleList[j]
                        if(siteNameArray.hasOwnProperty(['programName'])){
                            if(!arrayOfPrograms.includes(usersArray[i].systemRoleList[j].programName)){
                                arrayOfPrograms.push(usersArray[i].systemRoleList[j].programName)
                            }
                        }
                    }
                }
                if(arrayOfPrograms.length){
                    programArray.push(arrayOfPrograms);
                }else{
                    programArray.push([])
                }
                arrayOfPrograms = [];
            }
            if(this.sortField && this.sortField=='programName'){
                programArray.forEach((element1:any) => {
                    if(element1.length){
                        element1 = this.sortString(element1)
                    }
                });
            }
           this.programNameArray=programArray;
    return programArray;

        }

        getPostion(data:any){
        let arrayOfPrograms:any=[]
        let programArray:any=[]
        let usersArray = data.data.results
            for(let i=0;i<usersArray.length;i++){
                if(usersArray[i].systemRoleList){
                    for(let j=0;j<usersArray[i].systemRoleList.length;j++){
                        let siteNameArray = usersArray[i].systemRoleList[j]
                        if(siteNameArray.hasOwnProperty(['position'])){
                            if(!arrayOfPrograms.includes(usersArray[i].systemRoleList[j].position)){
                                arrayOfPrograms.push(usersArray[i].systemRoleList[j].position)
                            }
                        }
                    }
                }
                if(arrayOfPrograms.length){
                    programArray.push(arrayOfPrograms);
                }else{
                    programArray.push([])
                }
                arrayOfPrograms = [];
            }
            programArray.forEach((element1:any) => {
                if(element1.length){
                    element1 = this.sortString(element1)
                }
            });
           this.positionArray=programArray;
    return programArray;

        }

        presistResize(resize: { column: any, maxWidth: any, minWidth: any }) {
            manageListStore.mutateResizeData(resize)
        }

    callApionBackSpace(){
        if(this.globalSearch == ''){
            this.globalSearchApiStatus = 204;
            this.searchLoader=false;
            this.clearSelection();
            this.manageList();
        manageListStore.mutateUsersPayload(this.usersPayload)
        }
    }
        async downloadCsv(){
            this.disableDownloadButton = true;
            let notDisplayedColumns:Array<string> = [];
            this.displayColumn && this.displayColumn.forEach((item: any) => {
                if(item && !item.display){
                    notDisplayedColumns.push(item.name)
                }
            });

            let payload:IDownloadUsersPayload = {
                id : programList.accountDetail && programList.accountDetail.data && programList.accountDetail.data.accountId,
                searchField : this.currentPayload.SearchField,
                globalSearch: this.currentPayload.GlobalSearch,
                search : this.currentPayload.Search,
                sortField : this.currentPayload.SortField,
                sortDir : this.currentPayload.SortDir,
                columnsToHide: notDisplayedColumns ? notDisplayedColumns.join(',') : "",
                accountName: programList.accountDetail && programList.accountDetail.data && programList.accountDetail.data.accountName,
            }

            await DownloadUserCSV(payload).then((data)=>{
                 if(data.status == 200){
                    this.disableDownloadButton = false;
                }
            })
        }

        openActionDropdown(){
            this.bulkPrivilegeSearch.search=this.search
            this.bulkPrivilegeSearch.searchField=this.searchField
            let tempUsers = JSON.parse(JSON.stringify(this.allUsers));
            let selectedUsers: any = [];
            let rolesArr: object[]=[]
            let roleDataWithIds = []
            let invitationStatusDatawithIds = [];
            this.bulkPrivilegeRole=''
            tempUsers.forEach((element: any )=>{
                if (element.selected) {
                    selectedUsers.push(element);
                }
            })
            rolesArr = JSON.parse(JSON.stringify(selectedUsers));
            if(selectedUsers.length){
                roleDataWithIds=this.getSystemRoleWithId(rolesArr);
                invitationStatusDatawithIds = this.getInvitationStatus(rolesArr);
            }
            //Enable-Disable Add Privileges
            let legitRoles = ['program admin', 'session admin', 'staff', 'no access']
            if(this.searchField=='systemRole' && APP_CONST.ALL_ROLES.includes(this.search.toLowerCase())){
                this.bulkPrivilegeRole=this.search.toLowerCase()
            }
            else if(selectedUsers && selectedUsers.length && selectedUsers.length==1 && roleDataWithIds && roleDataWithIds[0].length==1){
                this.bulkPrivilegeRole=roleDataWithIds[0][0].name.toLowerCase()
            }
            else if(selectedUsers && selectedUsers.length > 1){
                let roleWithCount=JSON.parse(JSON.stringify(APP_CONST.ROLE_WITH_COUNT))
                roleDataWithIds.forEach((userRoles: any[]) => {
                    userRoles.forEach(role => {
                        roleWithCount.forEach((roletype: { id: any; count: number; }) => {
                            if(roletype.id == role.id){
                                roletype.count = roletype.count+1;
                            }
                        });
                    });
                });
                roleWithCount=this.sortByKey(roleWithCount,'count')
                if(roleWithCount[0].count!=roleWithCount[1].count){
                    this.bulkPrivilegeRole=roleWithCount[0].name
                }
            }
            if(this.bulkPrivilegeRole=='' || !legitRoles.includes(this.bulkPrivilegeRole) || selectedUsers.length==0){
                this.actionsAccountDropdown.forEach((element: { name: string; disable: boolean; }) => {
                    if(element.name == 'Add Privileges'){
                        element.disable = true
                        this.dropdownList = this.actionsAccountDropdown;
                    }
                });
            }else{
                this.actionsAccountDropdown.forEach((element: { name: string; disable: boolean; }) => {
                    if(element.name == 'Add Privileges'){
                        element.disable = false
                        this.dropdownList = this.actionsAccountDropdown;
                    }
                });
            }

            //Enable-Disable Send Invitation
            let noAccessIndex: number = 0;
            roleDataWithIds.forEach((roles: {name: string, id: number}[], index: number)=>{
                roles.forEach((role: {name: string, id: number})=>{
                    if(role.id != 9){
                        noAccessIndex = -1;
                    };
                });
            });

            //Enable-Disable Reset Password
            let statusIndex:number = -1
            invitationStatusDatawithIds.forEach((invitationStatuses: {invitationStatus: string, id: number}[], index: number) => {
                invitationStatuses.forEach((status: {invitationStatus: string, id: number})=>{
                    if((status.invitationStatus == '--')||(status.invitationStatus == 'Deactivated')){
                        statusIndex= index;
                    };
                });
            });
            //Enable-Disable Reset Password

            let noPrivilegeIndex: number = -1
            let allPrivileges = this.getAllPrivileges(rolesArr)
            let roleWithCountSendInvitation=JSON.parse(JSON.stringify(APP_CONST.ROLE_WITH_COUNT));
            roleDataWithIds.forEach((userRoles: any[]) => {
                userRoles.forEach(role => {
                    roleWithCountSendInvitation.forEach((roletype: { id: any; count: number; }) => {
                        if(roletype.id == role.id){
                            roletype.count = roletype.count+1;
                        }
                    });
                });
            });

            let isOnlyRoleidFour = roleWithCountSendInvitation && (roleWithCountSendInvitation[0].count > 0) && (roleWithCountSendInvitation[1].count == 0) && (roleWithCountSendInvitation[2].count == 0) && (roleWithCountSendInvitation[3].count == 0) && (roleWithCountSendInvitation[4].count == 0)
            if(selectedUsers && !isOnlyRoleidFour){
                allPrivileges.forEach((privilege: {sites: number[], programs: number[]}, index: number)=>{
                    if(!privilege.sites.length &&  !privilege.programs.length){
                        noPrivilegeIndex = index;
                    };
                });
            }
            this.actionsAccountDropdown.forEach((element: { name: string; disable: boolean; }) => {
                if(element.name == 'Send Invitation'){
                    element.disable = (noAccessIndex == -1 && noPrivilegeIndex == -1 && rolesArr.length) ? false : true;
                }
                else if(element.name == 'Reset Password'){
                    element.disable = (noAccessIndex == -1 && noPrivilegeIndex == -1 && statusIndex==-1 &&  rolesArr.length) ? false : true;
                }
            });
            this.dropdownList &&
            this.dropdownList.length &&
            this.dropdownList.forEach((element: any) => {
                if (element.name === 'Revoke Access') {

                }
                if (element.name == 'Add') {
                    element.disable = table.selectAll || table.allDataSelected.length || table.unSelectData.length ? true : false;
                } else if (element.name == 'Upload CSV') {
                    element.disable = table.selectAll || table.allDataSelected.length || table.unSelectData.length ? true : false;
                } else if (element.name == APP_CONST.ADD_PRIVILEGES_ACTION || element.name == APP_CONST.SEND_INVITATION_ACTION || element.name == APP_CONST.RESET_PASSWORD_ACTION ) {
                    element.disable = element.disable
                } else if (element.name == 'Enable'){
                    element.disable = table.selectedStatus == 'Enable' ? false : table.selectedStatus == 'All' ? true : true;
                } else if (element.name == 'Disable') {
                    element.disable = table.selectedStatus == 'Disable' ? false : table.selectedStatus == 'All' ? true : true;
                } else {
                    element.disable = table.selectAll || table.allDataSelected.length || table.unSelectData.length ? false : true;
                }
            })

            this.computeToolbarOptions();
        }

    getSystemRoleWithId(usersArray:any){
        let rolesArray: any = [];
        let rolesArrayWithIds: any = [];
        let mainArray: any = [];
        for (let i=0; i < usersArray.length; i++) {
           if(usersArray[i].systemRoleList){
            for (let j=0; j < usersArray[i].systemRoleList.length; j++) {
                let siteNameArray = usersArray[i].systemRoleList[j];
                if(siteNameArray.hasOwnProperty(['name'])) {
                    if(!rolesArray.includes(usersArray[i].systemRoleList[j].name)){
                        rolesArray.push(usersArray[i].systemRoleList[j].name);
                        rolesArrayWithIds.push({name:usersArray[i].systemRoleList[j].name, id:usersArray[i].systemRoleList[j].roleId});
                    }
                }
            }
           }
            if (rolesArrayWithIds.length) {
                mainArray.push(rolesArrayWithIds);
            } else {
                mainArray.push([])
            }
            rolesArray = [];
            rolesArrayWithIds = [];
        }
        return mainArray;
    }

        getAllPrivileges(usersArray:any){
                let mainArray: any = [];
                let tempArrayWithAllPrivileges:any = []
                for (let i=0; i < usersArray.length; i++) {
                    let userId = usersArray[i].userId
                    let allSites: number[] = []
                    let allPrograms: number[] = []
                   if(usersArray[i].systemRoleList){
                    let userRoles = 0;
                    usersArray[i].systemRoleList.forEach((element:any) => {
                        if(element.roleId>4){
                            userRoles = userRoles+1;
                        }
                    });
                    if(userRoles!=0){
                        for (let j=0; j < usersArray[i].systemRoleList.length; j++) {
                            if(!allSites.includes(usersArray[i].systemRoleList[j].siteId) && usersArray[i].systemRoleList[j].siteId!=0){
                                allSites.push(usersArray[i].systemRoleList[j].siteId)
                            }
                            if(!allPrograms.includes(usersArray[i].systemRoleList[j].programId) && usersArray[i].systemRoleList[j].programId!=0){
                                allPrograms.push(usersArray[i].systemRoleList[j].programId)
                            }
                        }
                        tempArrayWithAllPrivileges.push({userId: userId, programs: allPrograms, sites: allSites})
                    }
                    }
                   }
                    if (tempArrayWithAllPrivileges.length) {
                        mainArray = tempArrayWithAllPrivileges;
                    } else {
                        mainArray = []
                    }
                    tempArrayWithAllPrivileges = [];
                    return mainArray;
        }


        sortByKey(array: any[], key: string) {
            return array.sort((a, b) => {
                var x = a[key]; var y = b[key];
                return ((x > y) ? -1 : ((x < y) ? 1 : 0));
            });
        }

        searchGlobalData(){
            if(this.globalSearch.length>=3){
                this.searchLoader = true;
                this.clearSelection();
                this.manageList();
            }
        }

    destroyed() {
        table.mutateSelectAll(false);
        table.mutateFewCheckData('');
        table.mutateAllSelectedData([]);
        table.mutateUnSelectedData([]);
        let element = <HTMLInputElement>document.getElementById('all-data-checkbox');
        if (element) {
            element.checked = false;
        }
    }

    failedPrivileges(failedPrivilegesName: any){
        this.failedActionUserNames = failedPrivilegesName;
         /* ignore istanbul else */
         if(this.failedActionUserNames.length && UIkit.modal('#actions-ignored-popup')){
            UIkit.modal('#actions-ignored-popup').show()
            let elem : any = document.getElementById('popup-content') ;
            elem.scrollTo(0, 0);
            const body = <HTMLBodyElement>document.querySelector("body");
            body.classList.add("body-scroll-lock");
        }
    }

    closeActionIgnoredPopup(){
        if(UIkit.modal('#actions-ignored-popup')) {
            UIkit.modal('#actions-ignored-popup').hide();
            const body = <HTMLBodyElement>document.querySelector("body");
            body.classList.remove("body-scroll-lock");
        }
        this.failedActionUserNames = [];
        localStorage.removeItem('stripKey')
        let stripDisappear = true;
        setTimeout(()=>{
            localStorage.setItem('stripKey',JSON.stringify(stripDisappear))
          },100)
    }

    openResetPasswordPopup() {
        this.userssId = [];
        let unSelectedIds: any = [];
        let selectedIds: any = [];
        if (table.selectAll) {
            if (table.unSelectData.length) {
                unSelectedIds = table.unSelectData.map((user: any) => {
                    return user.userId;
                });
            }
            if (unSelectedIds && unSelectedIds.length) {
                this.usersIdLength = this.total_items - unSelectedIds.length;
            } else {
                this.usersIdLength = this.total_items;
            }
        } else {
            selectedIds = table.allDataSelected.map((user: any) => {
                return user.userId;
            });
            this.usersIdLength = selectedIds.length;
        }
        this.allUsers.forEach((user: any) => {
            if (user.selected) {
                this.userssId.push(user.userId);
            }
        });

        if (UIkit.modal('#reset-password-popup')) {
            UIkit.modal('#reset-password-popup').show();
        }

        if (this.userssId.length == 1) {
            this.isCountAvailable = false;
            this.countOrName = `${table.allDataSelected[0].firstName + " " + table.allDataSelected[0].lastName}.`;
        } else {
            this.isCountAvailable = true;
            this.countOrName = this.userssId.length;
        }
    }

    resetPassword(password: any) {
        const tempPayload = JSON.parse(JSON.stringify(this.currentPayload));
        let payload = { ...tempPayload, isReport: false, password: password, selectedIds: [], notSelectedIds: [], isAllSelected: table.selectAll, Search: decodeURIComponent(this.currentPayload.Search) };
        delete payload.Count;
        delete payload.Page;
        let unSelectedIds: any = [];
        if (table.selectAll) {
            if (table.unSelectData.length) {
                unSelectedIds = table.unSelectData.map((user: any) => {
                    return user.userId;
                });
            }
        }
        payload.notSelectedIds = unSelectedIds;
        let userIds: any = this.allDataSelected.map((user: any) => {
            return user.userId;
        });
        payload.selectedIds = userIds;
        this.failedActionUserNames = [];
        this.pageStatus = this.isLoaderVisible?false:this.pageStatus;
        this.allUsersPageStatus = this.isLoaderVisible?false:this.allUsersPageStatus;
        let progressBar: any=this.$refs.resetPassword;
        progressBar.isValid = false;
        bulkResetPassword(payload)
            .then((res) => {
                /* istanbul ignore else */
                if (res.status === APP_CONST.RESPONSE_200) {
                    if(progressBar){
                        progressBar.apiResponseCode = APP_CONST.RESPONSE_200;
                        progressBar.isValid = true
                    }
                    res && res.data && res.data.data && res.data.data.fail && res.data.data.fail.forEach((failUsers: any) => {
                        let fullName = failUsers.firstName + " " + failUsers.lastName;
                        this.failedActionUserNames.push(fullName);
                    });
                    if (this.failedActionUserNames && this.failedActionUserNames.length == APP_CONST.ZERO) {
                        this.resetPasswordOrangeStripFunction();
                    }else if(this.failedActionUserNames && this.failedActionUserNames.length){
                        setTimeout(() => {
                            this.failedPrivileges(this.failedActionUserNames);
                        }, 500);
                    }
                }else if((res.status === APP_CONST.RESPONSE_400) && progressBar) {
                    progressBar.apiResponseCode = APP_CONST.RESPONSE_400;
                } else if((res.status === APP_CONST.RESPONSE_500) && progressBar) {
                    progressBar.apiResponseCode = APP_CONST.RESPONSE_500;
                }
                this.onResetPasswordClose();
                this.getData();
            })
            .catch((error: any) => {
                console.log(error)
            });

    }

    onResetPasswordClose() {
        this.userssId = [];
        this.removeChecked();
        this.clearSelection();
    }

    resetPasswordOrangeStripFunction() {
        Vue.prototype.$eventHub.$emit(APP_CONST.RESET_PASSWORD_INDICATOR_STRIP, {
            component: APP_CONST.RESET_PASSWORD,
            stripShow: APP_CONST.TRUE,
            sendInvitationStripShow: APP_CONST.TRUE,
            stripText:
                APP_CONST.PASSWORD_RESET_CONFIRMATION_TEXT,
        })
        this.statusChangeTimer = window.setTimeout(() => {
            Vue.prototype.$eventHub.$emit(APP_CONST.RESET_PASSWORD_INDICATOR_STRIP, {
                component: APP_CONST.BLANK,
                stripShow: APP_CONST.FALSE,
                sendInvitationStripShow: APP_CONST.FALSE,
                stripText: APP_CONST.BLANK,
            });
        }, APP_CONST.AUTO_SAVE_INTERVAL);
    }

    getInvitationStatus(usersArray:any){
        let invitationStatusArray: any = [];
        let invitationStatusArrayWithIds: any = [];
        let mainArray: any = [];
        for (let i=0; i < usersArray.length; i++) {
           if(usersArray[i].systemRoleList){
            for (let j=0; j < usersArray[i].systemRoleList.length; j++) {
                let siteNameArray = usersArray[i].systemRoleList[j];
                if(siteNameArray.hasOwnProperty(['invitationStatus'])) {
                    if(!invitationStatusArray.includes(usersArray[i].systemRoleList[j].invitationStatus)){
                        invitationStatusArray.push(usersArray[i].systemRoleList[j].invitationStatus);
                        invitationStatusArrayWithIds.push({invitationStatus:usersArray[i].systemRoleList[j].invitationStatus, id:usersArray[i].systemRoleList[j].id});
                    }
                }
            }
           }
            if (invitationStatusArrayWithIds.length) {
                mainArray.push(invitationStatusArrayWithIds);
            } else {
                mainArray.push([])
            }
            invitationStatusArray = [];
            invitationStatusArrayWithIds = [];
        }
        return mainArray;
    }

  /**
   * Handles the change of the number of items per page.
   *
   * @param {number} numberOfItems - The number of items per page.
   */
  itemsPerPageChanged(numberOfItems: number) {
    this.searchCount = numberOfItems;
    this.page = 1;
    manageListStore.mutateUsersPayload({ ...manageListStore.usersPayload, count: numberOfItems });
    LocalStorage.saveInLocalStorage(`${this.tableName}_searchCount`, numberOfItems);
    this.manageList();
  }
}

