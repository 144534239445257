import { Vue, Component, Prop } from 'vue-property-decorator'
import { ScreenText } from '@/lang/ScreenText'
import manageList from '@/store/modules/manageList';

@Component
export class PrimaryAccountAdminAlert extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    public continue : boolean = false;

    @Prop()
    user! :any;

   get primaryAccountData(){
       return manageList.accountAdminDetails
   }

    public getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }


    continueCancel(){
        this.continue = true;
        this.$emit('continuePopup',this.continue)     
    }    

}